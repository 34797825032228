import React, { useState } from 'react';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
// import { name, random } from 'faker';
import PersonIcon from '@material-ui/icons/Person';
import PersonOutlinedIcon from '@material-ui/icons/PersonOutlined';
import { TextField, CircularProgress } from '@material-ui/core';
import { RenderInputParams } from '@material-ui/lab';
import { FetchPolicy } from 'apollo-client';
import { client } from '../..';
import { IAutoCompleteItem } from '../../typings/autoComplete';
import { showNotification } from '../../App';
import { InfiniteAutocomplete } from '../../components/InfiniteAutocomplete';
import gql from 'graphql-tag';
import { useDebouncedCallback } from 'use-debounce/lib';
import clsx from 'clsx';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
        width: '100%',
    },
    textField: {
        width: '100%',
    },
    iconSelected: {
        width: 17,
        height: 17,
        marginRight: 5,
        marginLeft: -2,
      },
      color: {
        width: 14,
        height: 14,
        flexShrink: 0,
        borderRadius: 3,
        marginRight: 8,
        marginTop: 2,
      },
      text: {
        flexGrow: 1,
      },
      close: {
        opacity: 0.6,
        width: 18,
        height: 18,
      },
      renderWrapper: {
          padding: '15px 0'
      },
      customRenderWrapper: {
        display: 'flex',
        margin: '0 -10px',
        width: '100%',
        '& > div': {
            padding: '0 10px',
            fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
            fontWeight: 400,
        },
        '& .icon-wrapper': {
            display: 'flex',
            alignItems: 'center',
        },
        '& .other': {
            flex: 1,
            display: 'flex',
            flexFlow: 'column',
            '& .primary': {
                fontSize: '1rem',
                lineHeight: 1.5,
                letterSpacing: '0.00938em',
            },
            '& .secondary': {
                color: 'rgba(0, 0, 0, 0.54)',
                fontSize: '0.875rem',
                lineHeight: 1.43,
                letterSpacing: '0.01071em',
            }
        }
      }
  })
);

interface StaffSelectorProps {
    multiple?: boolean;
    label?: string;
    name: string;
    // tslint:disable-next-line: no-any
    value?: IAutoCompleteItem | any; // value only available in single select
    onSelection?: (value: IAutoCompleteItem | IAutoCompleteItem[], name: string) => void;
    id?: string;
    includeStaff?: boolean;
    includeIndividual?: boolean;
    includeOrganisation?: boolean;
    disabled?: boolean;
    disableCloseOnSelect?: boolean;
    disablePortal?: boolean;
    onBlur?: React.FocusEventHandler<HTMLDivElement>;
    error?: boolean;
    helperText?: string;
    required?: boolean;
    className?: string;
}

interface StaffSelectorState {
    hasNextPage: boolean;
    isNextPageLoading: boolean;
    offset: number;
    filter: string;
    // tslint:disable-next-line: no-any
    items: any;
}

export const StaffSelector: React.FC<StaffSelectorProps> = props => {

    const classes = useStyles();
    const loadBlockLimit = 200;
    
    const [state, setState] = useState<StaffSelectorState>({
        hasNextPage: true,
        isNextPageLoading: false,
        offset: 0,
        filter: '',
        items: [],
    });

    const [delayedonInputChange] = useDebouncedCallback(
        // function
        (event: React.ChangeEvent<{}>, value: string, reason: 'input' | 'reset' | 'clear') => {

            let isClear = false;
            let offset = 0;
            let filter = '';

            if (reason === 'input') {
                
                filter = value;
                isClear = true;

                fetchData(filter, offset, isClear);
            }
        },
        // delay in ms
        500
    );
    
    const fetchData = (filter: string, offset: number, isClear: boolean) => {

        setState((prevState) => {
            return {
                ...prevState,
                isNextPageLoading: true,
            };
        });

        const staffQueryParams: StaffQueryParams = {
            filter: filter,
            first: loadBlockLimit,
            offset: offset,
        };

        retrieveStaffListData(
            staffQueryParams,
            false, // this.state.isRefresh, -- Since we are updating and creating, we do not fetch cached data
            // tslint:disable-next-line: no-console
            (data) => onRetrieveNameSummary(data, filter, offset, isClear),
            // tslint:disable-next-line:no-any
            function (reason: any): void {
                showNotification(null, reason, 'error');

                setState((prevState) => {
                    return {
                        ...prevState,
                        isNextPageLoading: false,
                    };
                });
            }
        );
    };

    const onRetrieveNameSummary = (data: StaffListData, filter: string, offset: number, isClear: boolean) => {

        const staffSummaryData = data.party.staffSummaries.staffSummary;
        const recordCount = data.party.staffSummaries.recordCount;

        // tslint:disable-next-line: no-any
        let itemsList: any = [];

        let items = state.items;

        if (!isClear) {
            itemsList = [...state.items];
        } else {
            items = [];
        }

        if (items && items.length === 0) {
            
            itemsList = staffSummaryData.map( (source: StaffSummary) => ({
                ...source,
                value: source.staffGuid, 
                label: source.name 
            }));

        } else {
            for (const staff of staffSummaryData) {
                // tslint:disable-next-line: no-any
                const isExists = items.filter((item: any) => item.value === staff.staffGuid).length;

                if (isExists === 0) {
                    itemsList.push({
                        ...staff,
                        label: staff.name,
                        value: staff.staffGuid
                    });
                }
            }
        }

        setState((prevState) => {
            return {
                ...prevState,
                filter: filter,
                isNextPageLoading: false,
                items: itemsList,
                offset: offset + loadBlockLimit,
                hasNextPage: staffSummaryData.length !== 0 && (offset + loadBlockLimit) < recordCount,
            };
        });

    };

    // tslint:disable-next-line: no-any
    const loadNextPage = (...args: any) => {
        
        if (state.hasNextPage && !state.isNextPageLoading) {
            fetchData(state.filter, state.offset, false);
        }
    };

    const onClose = (event: React.ChangeEvent<{}>) => {

        setState((prevState) => {
            return {
                ...prevState,
                isNextPageLoading: false,
                hasNextPage: true,
                filter: '',
                offset: 0,
                items: []
            };
        });
    };

    const onOpen = (event: React.ChangeEvent<{}>) => {
        fetchData(state.filter, 0, true);
    };

    const onSelection = (selection: IAutoCompleteItem, name: string) => {

        if (props.onSelection) {
            props.onSelection(selection, name);
        }
    };

    const getInputProps = (params: RenderInputParams, isLoading?: boolean) => {
        return  ({   
            ...params.InputProps,
            endAdornment: (
                <React.Fragment>
                  {isLoading ? <CircularProgress color="primary" size={20} /> : null}
                  {params.InputProps.endAdornment}
                </React.Fragment>
              ),     
         });
    };

    return (
        <div className={clsx(classes.root, props.className)}>
            <InfiniteAutocomplete
                id={props.id}
                itemSize={70}
                disabled={props.disabled}
                loading={state.isNextPageLoading}
                disablePortal={props.disablePortal}
                name={props.name}
                disableCloseOnSelect={props.disableCloseOnSelect}
                isMultiple={props.multiple}
                value={props.value}
                hasNextPage={state.hasNextPage}
                isNextPageLoading={state.isNextPageLoading}
                items={state.items}
                loadNextPage={loadNextPage}
                // disableCloseOnSelect={true}
                className={classes.textField}
                disableListWrap={true}
                onSelection={onSelection}
                onClose={onClose}
                onOpen={onOpen}
                getOptionLabel={(option: IAutoCompleteItem) => option.label}
                getOptionSelected={(option, value) => value.value === option.value}
                onInputChange={delayedonInputChange}
                onBlur={props.onBlur}
                renderOption={(option, { selected }) => {
                    return (
                        <div className={classes.customRenderWrapper}>
                            <div className="icon-wrapper">
                                {option.isStaff ? <PersonIcon fontSize="large"/> : <PersonOutlinedIcon fontSize="large"/>}
                            </div>
                            <div className="other">
                                <div className="primary">{option.label}</div>
                                <div className="secondary">{option.email}</div>
                            </div>
                        </div>
                    );
                }}
                renderInput={params => (
                    <TextField
                        {...params}
                        variant="standard"
                        label={props.label}
                        fullWidth={true}
                        InputProps={getInputProps(params, state.isNextPageLoading)}
                        error={props.error}      
                        helperText={props.helperText} 
                        required={props.required}
                    />
                )}
            />
        </div>
    );
};

export function retrieveStaffListData(
    query: StaffQueryParams,
    refreshData: boolean,
    onSuccess: (data: StaffListData) => void,
    // tslint:disable-next-line:no-any
    onError: (reason: any) => void
): void {
    var fetchPolicy: FetchPolicy = refreshData === true ? 'network-only' : 'cache-first';
    client
        .query({
            query: StaffQuery,
            variables: {
                filter: query.filter,
                first: query.first,      
                offset: query.offset
            },
            fetchPolicy: fetchPolicy,
        })
        // tslint:disable-next-line:no-any
        .then((results: { data: any }) => {
            onSuccess(results.data);
        })
        // tslint:disable-next-line:no-any
        .catch((reason: any) => {
            onError(reason);
        });
}

export interface StaffQueryParams {
    offset?: number; 
    first?: number;
    filter?: string;
}

export interface StaffListData {
    loading?: boolean;
    networkStatus?: number;
    party: Party;
}

export interface Party {
    staffSummaries: StaffSummaries;    
}

export interface StaffSummaries {
    recordCount: number;
    staffSummary: StaffSummary[];
}

export interface StaffSummary {
    staffGuid: string;
    staffId: number;
    name: string;
    emailAddress: string;
}

const StaffQuery = gql`
    query staffQuery($offset: Int, $first: Int, $filter: String){
        party{
            staffSummaries(offset:$offset, first:$first, filter:$filter){
                recordCount,
                staffSummary{
                    staffGuid,
                    staffId,
                    name,
                    emailAddress
                }
            }
        }
    }
`;