import { BaseTextFieldProps } from '@material-ui/core';
import gql from 'graphql-tag';
import React from 'react';
import { useQuery } from 'react-apollo';
import TreeSelect, { TreeSelectNode } from '../../components/TreeSelect';

interface MatterTreeTypeSelectorProps extends Omit<BaseTextFieldProps, 'value'> {
    state: string;
    onSelection: (value: TreeSelectNode | null, name: string) => void;
    value?: TreeSelectNode | null;
}

export const MatterTreeTypeSelector: React.FC<MatterTreeTypeSelectorProps> = (props) => {
    // const [value, setValue] = useState<TreeSelectNode | null>(null);

    const matterTypeQuery = useMatterTypeTree();

    const matterTypeData = !matterTypeQuery.loading ? JSON.parse(matterTypeQuery.data!.lookup.matterTypeTreeJson) : [];

    const convertChildrenToLowerCase = (opt: MatterTypeTreeJsonData[]): TreeSelectNode[] => {
        const convertedOptions: TreeSelectNode[] = [];

        for (let i = 0; i < opt.length; i++) {
            const { Children, Description, ItemId, ...rest } = opt[i];

            const convertedOption: TreeSelectNode = {
                ...rest,
                Description: Description,
                ItemId: ItemId,
                label: Description,
                value: ItemId,
            };

            if (Children) {
                convertedOption.children = convertChildrenToLowerCase(Children);
            }

            convertedOptions.push(convertedOption);
        }

        return convertedOptions;
    };

    // filterObjectsByState filter the result that match in the states in multidimensional array base on the children and return it in the same format including the parent
    const filterObjectsByState = (data: MatterTypeTreeJsonData[], searchString: string): MatterTypeTreeJsonData[] => {
        if (searchString.length === 0) {
            return data;
        }
        return data.reduce<MatterTypeTreeJsonData[]>((result, item) => {
            const filteredChildren = item.Children ? filterObjectsByState(item.Children, searchString.toLowerCase()) : [];
            if (item.State?.toLowerCase() === searchString.toLowerCase() || item.State?.toLowerCase() === 'all' || filteredChildren.length) {
                result.push({ ...item, Children: filteredChildren });
            }
            return result;
        },                                           []);
    };

    const optionsFilterByState = filterObjectsByState(matterTypeData, props.state);

    const options = convertChildrenToLowerCase(optionsFilterByState);

    const onSelection = (newValue: TreeSelectNode | null) => {
        // console.log('MatterTreeTypeSelector', newValue);
        // console.log(newValue);
        // setValue(newValue);
        if (props.onSelection) {
            props.onSelection(newValue, props.name ?? 'matterTypeTree');
        }
    };

    return (
        <TreeSelect 
            {...props}
            options={options} 
            onSelection={onSelection} 
            value={props.value} 
            loading={matterTypeQuery.loading} 
        />
    );
};

export interface MatterTypeTreeJsonData {
    IsFolder: boolean;
    ParentId: string;
    State?: string;
    Description: string;
    ItemId: string;
    Children?: MatterTypeTreeJsonData[];
}

export interface MatterTypeTreeData {
    lookup: Lookup;
}

export interface Lookup {
    matterTypeTreeJson: string;
}

export const useMatterTypeTree = () => {
    const { data, error, loading } = useQuery<MatterTypeTreeData>(MatterTypeTreeQuery, {
        fetchPolicy: 'cache-first',
    });

    return {
        data,
        error,
        loading,
    };
};

export const MatterTypeTreeQuery = gql`
    query MatterTypeTree {
        lookup {
            matterTypeTreeJson
        }
    }
`;
