import React, { useState, useMemo, useEffect } from 'react';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import parse from 'autosuggest-highlight/parse';
import throttle from 'lodash/throttle';

const autocompleteService = { current: null };

const useStyles = makeStyles((theme) => ({
    icon: {
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(2),
    },
}));

export interface PlaceType {
    description: string;
    place_id: string;
    structured_formatting: {
        main_text: string;
        secondary_text: string;
        main_text_matched_substrings: [
            {
                offset: number;
                length: number;
            },
        ];
    };
}
interface GeocoderAddressComponent {
    long_name: string;
    short_name: string;
    types: string[];
}

export interface Address {
    premise: string;
    subPremise: string;
    floor: string;
    streetNumber: string;
    route: string;
    locality: string; // town
    sublocality: string;
    administrativeAreaLevel1: string; // state
    administrativeAreaLevel2: string;
    administrativeAreaLevel3: string;
    country: string;
    postalCode: string;
}

interface AddressSelectorProps { 
    label?: string;
    required?: boolean;
    helperText?: string;
    error?: boolean;
    className?: string;
    placeTypes?: string[];  // ['geocode' | 'address' | 'establishment' | '(regions)' | '(cities)'];
    name?: string;
    freeSolo?: boolean;
    homeCountry?: string;
    onSelection?: (
        placeType: PlaceType | null,
        address: Address,
        name: string
    ) => void;
    onInputChanged?: (value: string) => void;
    // tslint:disable-next-line: no-any
    value?: any;
    disabled?: boolean;
}

interface AddressSelectorState extends Address {
    error?: boolean;
}

export const AddressSelector: React.FC<AddressSelectorProps> = props => {

    const classes = useStyles();
    const [value, setValue] = useState<PlaceType | null>(null);
    const [inputValue, setInputValue] = useState('');
    const [options, setOptions] = useState<PlaceType[]>([]);
    // const loaded = useRef(false);

    const [state, setState] = useState<AddressSelectorState>({
        error: props.error,
        premise: '',
        subPremise: '',
        floor: '',
        streetNumber: '',
        route: '',
        locality: '', // tow,
        sublocality: '',
        administrativeAreaLevel1: '', // stat,
        administrativeAreaLevel2: '',
        administrativeAreaLevel3: '',
        country: '',
        postalCode: '',    
    });

    const fetch = useMemo(
        () =>
            throttle((request: { input: string }, callback: (results?: PlaceType[]) => void) => {
                const tempRequest = {
                    ...request,
                    types: props.placeTypes,
                    componentRestrictions: {country: props.homeCountry ? props.homeCountry : 'au'}
                };
                // tslint:disable-next-line: no-any
                (autocompleteService.current as any).getPlacePredictions(tempRequest, callback);
            // tslint:disable-next-line: align
            }, 200),
        [],
    );

    useEffect(() => {
        setState((prevState) => {
            return {
                ...prevState,
                error: props.error
            };
        });
    // tslint:disable-next-line: align
    }, [props.error]);

    useEffect(() => {
        setValue(props.value);
    // tslint:disable-next-line: align
    }, [props.value]);

    useEffect(() => {
        let active = true;

        // tslint:disable-next-line: no-any
        if (!autocompleteService.current && (window as any).google) {
            // tslint:disable-next-line: no-any
            autocompleteService.current = new (window as any).google.maps.places.AutocompleteService();
        }
        if (!autocompleteService.current) {
            return undefined;
        }

        if (inputValue === '') {
            setOptions(value ? [value] : []);
            return undefined;
        }

        fetch({ input: inputValue }, (results?: PlaceType[]) => {
            if (active) {
                let newOptions = [] as PlaceType[];

                if (value) {
                    newOptions = [value];
                }

                if (results) {
                    newOptions = [...newOptions, ...results];
                }

                setOptions(newOptions);
            }
        });

        return () => {
            active = false;
        };
    // tslint:disable-next-line: align
    }, [value, inputValue, fetch]);

    const onSuggestionSelected = (suggestion: PlaceType) => {
        // const selection: Address = new Address( );

        // Fetch the placeId from the suggession and auto complete suggession,
        // and make a request again using the place Id to fetch the complete address details
        const placeId = suggestion.place_id ? suggestion.place_id : '';

        const request: google.maps.places.PlaceDetailsRequest  = {
            fields: ['address_components'], // 'name', 'formatted_address', 'place_id', 'geometry', 
            placeId         
        };

        const googleMapElement = document.getElementById('googleMap') as HTMLInputElement;
        const map = new google.maps.Map(googleMapElement);
        const service = new google.maps.places.PlacesService(map);
        
        // tslint:disable-next-line
        service.getDetails(request, (results: any, status: any) => {
            if (status === google.maps.places.PlacesServiceStatus.OK) {
                
                const selection: Address = {
                    premise: '',
                    subPremise: '',
                    floor: '',
                    streetNumber: '',
                    route: '',
                    locality: '', // tow,
                    sublocality: '',
                    administrativeAreaLevel1: '', // state,
                    administrativeAreaLevel2: '',
                    administrativeAreaLevel3: '',
                    country: '',
                    postalCode: '',
                };

                if (results.address_components !== undefined) {

                    // console.log('address_components', results.address_components);
                    // If name === street2 then set value to street 2 and ignore street 1
                    // tslint:disable-next-line:prefer-for-of
                    for (let index = 0; index < results.address_components.length; index++) {
                        
                        const element: GeocoderAddressComponent = results.address_components[index];

                        if (element.types.includes('premise')) {
                            // tslint:disable-next-line: no-console
                            console.log('subpremise', element.short_name);       
                            selection.premise = selection.premise ? selection.premise : element.short_name;              
                        }

                        if (element.types.includes('subpremise')) {
                            // tslint:disable-next-line: no-console
                            console.log('subpremise', element.short_name);        
                            selection.subPremise = selection.subPremise ? selection.subPremise : element.short_name;             
                        }

                        if (element.types.includes('floor')) {
                            // tslint:disable-next-line: no-console
                            console.log('floor', element.short_name);
                            selection.floor = selection.floor ? selection.floor : element.short_name;                          
                        }

                        if (element.types.includes('street_number')) {
                            // tslint:disable-next-line: no-console
                            console.log('street_number', element.short_name);        
                            selection.streetNumber = selection.streetNumber ? selection.streetNumber : element.short_name;                  
                        }

                        if (element.types.includes('route')) {
                            // tslint:disable-next-line: no-console
                            console.log('route', element.long_name);           
                            selection.route = selection.route ? selection.route : element.long_name;              
                        }

                        if (element.types.includes('locality') || element.types.includes('postal_town')) {
                            // tslint:disable-next-line: no-console
                            console.log('locality', element.short_name);
                            selection.locality = selection.locality ? selection.locality : element.short_name;   
                        }

                        // This is specific to UK.
                        if (element.types.includes('postal_town')) {
                            selection.locality = selection.locality ? selection.locality : element.short_name;   
                        }

                        if (element.types.includes('sublocality')) {
                            // tslint:disable-next-line: no-console
                            console.log('sublocality', element.short_name);
                            selection.sublocality = selection.sublocality ? selection.sublocality : element.short_name;   
                        }

                        if (element.types.includes('administrative_area_level_1')) {
                            // tslint:disable-next-line: no-console
                            console.log('administrative_area_level_1', element.short_name);
                            // tslint:disable-next-line: max-line-length
                            selection.administrativeAreaLevel1 = selection.administrativeAreaLevel1 ? selection.administrativeAreaLevel1 : element.short_name;   
                        }

                        if (element.types.includes('administrative_area_level_2')) {
                            // tslint:disable-next-line: no-console
                            console.log('administrative_area_level_2', element.short_name);
                            // tslint:disable-next-line: max-line-length
                            selection.administrativeAreaLevel2 = selection.administrativeAreaLevel2 ? selection.administrativeAreaLevel2 : element.short_name;   
                        }

                        if (element.types.includes('administrative_area_level_3')) {
                            // tslint:disable-next-line: no-console
                            // console.log('administrative_area_level_3', element.short_name);
                            // tslint:disable-next-line: max-line-length
                            selection.administrativeAreaLevel3 = selection.administrativeAreaLevel3 ? selection.administrativeAreaLevel3 : element.short_name;   
                        }

                        if (element.types.includes('country')) {
                            // tslint:disable-next-line: no-console
                            console.log('country', element.long_name);
                            selection.country = selection.country ? selection.country : element.long_name;   
                        }

                        if (element.types.includes('postal_code')) {
                            // tslint:disable-next-line: no-console
                            console.log('postal_code', element.long_name);
                            selection.postalCode = selection.postalCode ? selection.postalCode : element.long_name;   
                        }                        
                    }

                    // tslint:disable-next-line: no-console
                    if (props.onSelection) {
                        props.onSelection(suggestion, selection, props.name ? props.name : 'address');
                    }
                }  
            }
        });   
    };

    return (
        <Autocomplete
            freeSolo={props.freeSolo}
            className={props.className}
            getOptionLabel={(option) => (typeof option === 'string' ? option : option.structured_formatting.main_text)}
            filterOptions={(x) => x}
            options={options}
            includeInputInList={true}
            filterSelectedOptions={true}
            value={value}
            autoComplete={false}
            disabled={props.disabled}
            onChange={(event: React.ChangeEvent<{}>, newValue: PlaceType | null) => {

                if (newValue != null) {
                    onSuggestionSelected(newValue);
                }
                // TODO: Else ?

                setOptions(newValue ? [newValue, ...options] : options);
                setValue(newValue);

            }}
            onInputChange={(event, newInputValue) => {

                if (props.onInputChanged) {
                    props.onInputChanged(newInputValue);
                }
                setInputValue(newInputValue);
            }}
            renderInput={(params) => (
                <TextField 
                    {...params} 
                    label={props.label ? props.label : 'Address'}
                    variant="standard" 
                    fullWidth={true} 
                    margin="none" 
                    required={props.required}
                    // InputProps={getInputProps(params, state.isLoading)}
                    error={state.error}      
                    helperText={props.helperText}    
                    autoComplete="off"
                />
            )}
            renderOption={(option) => {
                let parts;

                if (option && option.structured_formatting) {
                    const matches = option.structured_formatting.main_text_matched_substrings;
                    parts = parse(
                        option.structured_formatting.main_text,
                        // tslint:disable-next-line: no-any
                        matches.map((match: any) => [match.offset, match.offset + match.length]),
                    );
                }                

                return (
                    parts && (
                        <Grid 
                            container={true}
                            alignItems="center"
                        >
                            <Grid item={true}>
                                <LocationOnIcon className={classes.icon} />
                            </Grid>
                            <Grid item={true} xs={true}>
                                {parts && (
                                    parts.map((part, index) => (
                                        <span key={index} style={{ fontWeight: part.highlight ? 700 : 400 }}>
                                            {part.text}
                                        </span>
                                    ))
                                )}
                            <Typography variant="body2" color="textSecondary">
                                {option && option.structured_formatting && option.structured_formatting.secondary_text &&
                                    option.structured_formatting.secondary_text
                                }
                            </Typography>
                            </Grid>
                        </Grid>
                    )                                       
                );
            }}
        />
    );
};