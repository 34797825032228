/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
// import { name, random } from 'faker';
import gql from 'graphql-tag';
import { FetchPolicy } from 'apollo-client';
import { TextField, CircularProgress } from '@material-ui/core';
import { IAutoCompleteItem } from '../../typings/autoComplete';
import { showNotification } from '../../App';
import { client } from '../..';
import { InfiniteAutocomplete } from '../../components/InfiniteAutocomplete';
import { RenderInputParams } from '@material-ui/lab';
import { useDebouncedCallback } from 'use-debounce/lib';
import clsx from 'clsx';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
        minWidth: '200px',
    },
    textField: {
        width: '100%',
    },
    iconSelected: {
        width: 17,
        height: 17,
        marginRight: 5,
        marginLeft: -2,
      },
      color: {
        width: 14,
        height: 14,
        flexShrink: 0,
        borderRadius: 3,
        marginRight: 8,
        marginTop: 2,
      },
      text: {
        flexGrow: 1,
      },
      close: {
        opacity: 0.6,
        width: 18,
        height: 18,
      },
      renderWrapper: {
          padding: '15px 0'
      },
      customRenderWrapper: {
        display: 'flex',
        flexFlow: 'column',
        gap: '5px',
        '& .primary': {
            fontSize: '1rem',
            lineHeight: 1.5,
            letterSpacing: '0.00938em',
            // wordBreak: 'break-all',
            // wordWrap: 'break-word',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
            display: 'flex',
            alignItems: 'center',
            gap: '5px',
        },
        '& .secondary': {
            color: 'rgba(0, 0, 0, 0.54)',
            fontSize: '0.875rem',
            lineHeight: 1.43,
            letterSpacing: '0.01071em',
            // wordBreak: 'break-all',
            // wordWrap: 'break-word',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
        },
        '& .dataSource': {
            fontStyle: 'italic',
        }
      },
  })
);

export type OrganisationIAutoCompleteItem = IAutoCompleteItem & NameSummary;

interface OrganisationSelectorProps {
    isStaffOnly?: boolean;
    multiple?: boolean;
    label?: string;
    name: string;
    // tslint:disable-next-line: no-any
    value?: IAutoCompleteItem | any; // value only available in single select
    onSelection?: (value: OrganisationIAutoCompleteItem | OrganisationIAutoCompleteItem[] | IAutoCompleteItem | IAutoCompleteItem[], name: string) => void;
    id?: string;
    required?: boolean;
    helperText?: string;
    error?: boolean;
    className?: string;
    disablePortal?: boolean;
    disabled?: boolean;
}

interface OrganisationSelectorState {
    hasNextPage: boolean;
    isNextPageLoading: boolean;
    offset: number;
    items: OrganisationIAutoCompleteItem[];
    filter: string;
}

export const OrganisationSelector: React.FC<OrganisationSelectorProps> = props => {

    const classes = useStyles();
    const loadBlockLimit = 20;

    const [delayedonInputChange] = useDebouncedCallback(
        // function
        (event: React.ChangeEvent<{}>, value: string, reason: 'input' | 'reset' | 'clear') => {
            fetchData(value, 0); 
        },
        // delay in ms
        500
    );
    
    const [state, setState] = useState<OrganisationSelectorState>({
        hasNextPage: true,
        isNextPageLoading: false,
        offset: 0,
        items: [],
        filter: ''
    });

    useEffect(() => {
        fetchData('', 0);
    // tslint:disable-next-line: align
    }, []);

    const fetchData = (filter: string, offset: number) => {

        setState((prevState) => {
            return {
                ...prevState,
                isNextPageLoading: true,
                offset: offset,
                filter: filter
            };
        });

        const partyQueryParams: PartyQueryParams = {
            filter: filter.length > 0 ? filter : null,
            first: loadBlockLimit,
            offset: offset,
            includeOrganisation: true,
            includeIndividual: null,
            includeStaff: null,
        };

        retrievePartyData(
            partyQueryParams,
            true, // this.state.isRefresh, -- Since we are updating and creating, we do not fetch cached data
            // tslint:disable-next-line: no-console
            (data) => onRetrieveUserList(data, filter),
            // tslint:disable-next-line:no-any
            function (reason: any): void {
                showNotification(null, reason, 'error');

                setState((prevState) => {
                    return {
                        ...prevState,
                        isNextPageLoading: false,
                    };
                });
            }
        );
    };

    const onRetrieveUserList = (data: PartyData, filter: string) => {

        const nameSummaryData = data.party.nameSummaries.nameSummary;
        const recordCount = data.party.nameSummaries.recordCount;

        // tslint:disable-next-line: no-any
        let itemsList: any = [...state.items];

        if (state.items.length === 0) {
            itemsList = nameSummaryData.map( (source: NameSummary) => ({
                ...source,
                value: source.guidID.toString(), 
                label: source.name 
            }));
        } else {
            for (const nameSummary of nameSummaryData) {
                // tslint:disable-next-line: no-any
                const isExists = state.items.filter((item: OrganisationIAutoCompleteItem) => 
                    item.value.toString().toLowerCase().includes(nameSummary.guidID.toString().toLowerCase())).length;

                if (isExists === 0) {
                    itemsList.push({
                        ...nameSummary,
                        label: nameSummary.name,
                        value: nameSummary.guidID.toString()
                    });
                }
            }
        }

        setState((prevState) => {
            return {
                ...prevState,
                filter: filter,
                isNextPageLoading: false,
                items: itemsList,
                offset: state.offset + loadBlockLimit,
                hasNextPage: nameSummaryData.length !== 0 && (state.offset + loadBlockLimit) < recordCount,
            };
        });
    };

    // tslint:disable-next-line: no-any
    const loadNextPage = (...args: any) => {
        if (state.hasNextPage && !state.isNextPageLoading) {
            fetchData(state.filter, state.offset);
        }
    };

    const getInputProps = (params: RenderInputParams, isLoading?: boolean) => {
        return  ({   
            ...params.InputProps,
            endAdornment: (
                <React.Fragment>
                  {isLoading ? <CircularProgress color="primary" size={20} /> : null}
                  {params.InputProps.endAdornment}
                </React.Fragment>
              ),     
         });
    };

    return (
        <div className={clsx(classes.root, props.className)}>
            <InfiniteAutocomplete
                id={props.id}
                itemSize={55}
                loading={state.isNextPageLoading}
                disablePortal={props.disablePortal}
                name={props.name}
                isMultiple={props.multiple}
                value={props.value}
                hasNextPage={state.hasNextPage}
                isNextPageLoading={state.isNextPageLoading}
                items={state.items}
                loadNextPage={loadNextPage}
                // disableCloseOnSelect={true}
                className={classes.textField}
                disableListWrap={true}
                onSelection={props.onSelection}
                getOptionLabel={(option: OrganisationIAutoCompleteItem) => option.label}
                onInputChange={delayedonInputChange}
                disabled={props.disabled}
                renderInput={params => (
                    <TextField
                        {...params}
                        variant="standard"
                        label={props.label}
                        fullWidth={true}
                        margin="none" 
                        required={props.required}
                        InputProps={getInputProps(params, state.isNextPageLoading)}
                        error={props.error}      
                        helperText={props.helperText}  
                    />
                )}
                renderOption={(option, { selected }) => {
                    return (
                        <div className={classes.customRenderWrapper}>
                            <div className="primary">
                                {option.label}
                            </div>
                            {!option.isFromDataSource ? (
                                <div className="secondary dataSource">
                                    Entered
                                </div>
                            ) : null}
                        </div>
                    );
                }}
            />
        </div>
    );
};

export function retrievePartyData(
    query: PartyQueryParams,
    refreshData: boolean,
    onSuccess: (data: PartyData) => void,
    // tslint:disable-next-line:no-any
    onError: (reason: any) => void
): void {
    var fetchPolicy: FetchPolicy = refreshData === true ? 'network-only' : 'cache-first';
    client
        .query({
            query: PartyQuery,
            variables: {
                filter: query.filter,
                first: query.first,
                offset: query.offset,
                includeIndividual: query.includeIndividual,
                includeOrganisation: query.includeOrganisation,
                includeStaff: query.includeStaff
            },
            fetchPolicy: fetchPolicy,
        })
        // tslint:disable-next-line:no-any
        .then((results: { data: any }) => {
            onSuccess(results.data);
        })
        // tslint:disable-next-line:no-any
        .catch((reason: any) => {
            onError(reason);
        });
}

export interface PartyQueryParams {
    offset: number | null;
    first: number | null;
    filter: string | null;
    includeIndividual: Boolean | null;
    includeOrganisation: Boolean | null;
    includeStaff: Boolean | null;
}

export interface PartyData {
    loading?: boolean;
    networkStatus?: number;
    party: Party;
}

export interface Party {
    nameSummaries: NameSummaries;
}

export interface NameSummaries {
    recordCount: number;
    nameSummary: NameSummary[];
}

export interface NameSummary {
    id: number;
    guidID: string;
    nameId: number;
    name: string;
    address: string;
    isFromDataSource: boolean;
}

const PartyQuery = gql`
    query Party(
        $offset: Int,
        $first: Int,
        $filter: String,
        $includeIndividual: Boolean,
        $includeOrganisation: Boolean,
        $includeStaff: Boolean,
    ){
        party{
            nameSummaries(
                offset: $offset,
                first: $first,
                filter: $filter,
                includeIndividual: $includeIndividual,
                includeOrganisation: $includeOrganisation,
                includeStaff: $includeStaff,
            ){
                recordCount
                nameSummary{
                    id,
                    nameId
                    guidID,
                    name
                    address
                    isFromDataSource
                }
            }
        }
    }
`;