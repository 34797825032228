/* eslint-disable no-use-before-define */
import React, { useState, useEffect } from 'react';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { makeStyles } from '@material-ui/core/styles';
import { IAutoCompleteItem } from '../../typings/autoComplete';

const useStyles = makeStyles({
    option: {
        // fontSize: 15,
        // '& > span': {
        //   marginRight: 10,
        //   fontSize: 18,
        // },
        width: '100%',
    },
});

interface SelectorProps {
    onSelection?: (selection: IAutoCompleteItem, name: string) => void;
    // tslint:disable-next-line: no-any
    items?: any;
    required?: boolean;
    label?: string;
    name: string;
    value: string; // GuidId
    disabled?: boolean;
    error?: boolean;
    className: string;
    helperText: string;
}

interface ValueDetails {
    label: string;
    value: string;
}

export const GenderSelector: React.FunctionComponent<SelectorProps> = (props) => {
    const classes = useStyles();

    const [values, setValues] = useState<ValueDetails>({
        label: '',
        value: '',
    });

    // tslint:disable-next-line: no-any
    const handleChange = (event: object, item: any) => {
        setValues({
            label: item ? item.label : '',
            value: item ? item.value : '',
        });

        if (props.onSelection) {
            props.onSelection(
                {
                    label: item ? item.label : '',
                    value: item ? item.value : '',
                },
                props.name
            );
        }
    };

    useEffect(() => {
        // - Ran only once
        // If we recieve a leadGuid, then fetch LeadDetails
        if (props.value) {
            setValues({
                label: props.value,
                value: props.value,
            });
        }
    },        [props.value]);

    return (
        <Autocomplete
            id="gender-select"
            options={Gender as GenderType[]}
            classes={{option: classes.option}}
            getOptionLabel={(option) => option.label}
            value={{ value: values.value, label: values.label }}
            onChange={handleChange}
            autoSelect={true}
            disabled={props.disabled}
            renderInput={(params) => (
                <TextField
                    {...params}
                    label={props.label ? props.label : 'Gender'}
                    variant="standard"
                    inputProps={{
                        ...params.inputProps,
                    }}
                    className={props.className}
                    helperText={props.helperText}
                />
            )}
        />
    );
};

interface GenderType {
    label: string;
    value: string;
}

// From https://bitbucket.org/atlassian/atlaskit-mk-2/raw/4ad0e56649c3e6c973e226b7efaeb28cb240ccb0/packages/core/select/src/data/countries.js
const Gender = [
    { label: 'Unknown', value: 'Unknown' },
    { label: 'Male', value: 'Male' },
    { label: 'Female', value: 'Female' },
];
