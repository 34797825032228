/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useMemo } from 'react';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import { Autocomplete, RenderInputParams } from '@material-ui/lab';
import { TextField, CircularProgress } from '@material-ui/core';
import match from 'autosuggest-highlight/match';
import parse from 'autosuggest-highlight/parse';
import { IAutoCompleteItem } from '../../typings/autoComplete';
import gql from 'graphql-tag';
import { useQuery } from 'react-apollo';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
        minWidth: '200px',
    },
  })
);

interface TitleAutocompleteSelectorProps {
    disabled?: boolean;
    required?: boolean;
    multiple?: boolean;
    // tslint:disable-next-line: no-any
    value?: IAutoCompleteItem | any; // value only available in single select
    error?: boolean;
    onSelection?: (value: IAutoCompleteItem | IAutoCompleteItem[], name: string) => void;
    className?: string;
    helperText?: string;
    name?: string;
    label?: string;
    disablePortal?: boolean;
}

interface TitleAutocompleteSelectorState {
    isLoading: boolean;
    error?: boolean;
    options: IAutoCompleteItem[];
}

export const TitleAutocompleteSelector: React.FC<TitleAutocompleteSelectorProps> = props => {

    const classes = useStyles();

    const [state, setState] = useState<TitleAutocompleteSelectorState>({
        isLoading: false,
        error: props.error,
        options: [],
    });

    const partyTitleQuery = useQuery<PartyTitleLookupData>(PartyTitleLookupQuery, {
        fetchPolicy: 'cache-first',
    });

    useMemo(() => {
        if (partyTitleQuery.data && partyTitleQuery.data.lookup && partyTitleQuery.data.lookup.partyTitle && !partyTitleQuery.loading) {
            
            const titles = partyTitleQuery.data.lookup.partyTitle.map((title: PartyTitle) => {
                return {
                    label: title.description,
                    value: title.description,
                };
            });
            
            setState((prevState) => {

                return {
                    ...prevState,
                    options: titles
                };
            });
        }
    // tslint:disable-next-line: align
    }, [partyTitleQuery.data, partyTitleQuery.loading, partyTitleQuery.error]);

    // tslint:disable-next-line: no-any
    const handleChange = (event: React.ChangeEvent<{}>, value: any) => {
        // tslint:disable-next-line: no-console
        console.log('onChange', value);
        if (props.onSelection) {
            props.onSelection(value, props.name ? props.name : 'status');
        }
    };

    const getInputProps = (params: RenderInputParams, isLoading?: boolean) => {
        return  ({   
            ...params.InputProps,
            endAdornment: (
                <React.Fragment>
                  {isLoading ? <CircularProgress color="primary" size={20} /> : null}
                  {params.InputProps.endAdornment}
                </React.Fragment>
              ),     
         });
    };

    return (
        <div className={`${classes.root} ${props.className}`}>
            { props.multiple ? 
                (
                    <Autocomplete
                        value={props.value}
                        multiple={true}
                        loading={partyTitleQuery.loading}
                        disabled={props.disabled}
                        onChange={handleChange}
                        options={state.options}
                        disablePortal={props.disablePortal} // to Stop the dropdown select from closing the form
                        // tslint:disable-next-line: jsx-no-lambda
                        getOptionLabel={(option: IAutoCompleteItem) => option.label}                    
                        // tslint:disable-next-line: jsx-no-lambda
                        renderInput={params => (
                            <TextField 
                                {...params} 
                                label={props.label ? props.label : 'Status'}
                                variant="standard" 
                                fullWidth={true} 
                                margin="none" 
                                required={props.required}
                                InputProps={getInputProps(params, state.isLoading)}
                                error={state.error}      
                                helperText={props.helperText}                      
                            />
                        )}
                        // tslint:disable-next-line: jsx-no-lambda
                        renderOption={(option, { inputValue }) => {
                            const matches = match(option.label, inputValue);
                            const parts = parse(option.label, matches);
                    
                            return (
                                <div>
                                    {parts.map((part, index) => (
                                        <span key={index} style={{ fontWeight: part.highlight ? 700 : 400 }}>
                                            {part.text}
                                        </span>
                                    ))}
                                </div>
                            );
                        }}
                    />
                )
                :
                (
                    <Autocomplete
                        value={props.value}
                        loading={partyTitleQuery.loading}
                        disabled={props.disabled}
                        onChange={handleChange}
                        options={state.options}
                        disablePortal={props.disablePortal} // to Stop the dropdown select from closing the form
                        // tslint:disable-next-line: jsx-no-lambda
                        getOptionLabel={(option: IAutoCompleteItem) => option.label}         
                        // tslint:disable-next-line: jsx-no-lambda
                        renderInput={params => (
                            <TextField 
                                {...params} 
                                label={props.label ? props.label : 'Status'}
                                variant="standard" 
                                fullWidth={true} 
                                margin="none" 
                                required={props.required}
                                InputProps={getInputProps(params, state.isLoading)}
                                error={state.error}                            
                            />
                        )}
                        // tslint:disable-next-line: jsx-no-lambda
                        renderOption={(option, { inputValue }) => {
                            const matches = match(option.label, inputValue);
                            const parts = parse(option.label, matches);
                    
                            return (
                                <div>
                                {parts.map((part, index) => (
                                    <span key={index} style={{ fontWeight: part.highlight ? 700 : 400 }}>
                                    {part.text}
                                    </span>
                                ))}
                                </div>
                            );
                        }}
                    />
                )
            }
        </div>
    );
};

export interface PartyTitleLookupData {
    lookup: Lookup;
}

export interface Lookup {
    partyTitle: PartyTitle[];
}

export interface PartyTitle {
    id:          number;
    description: string;
}

export const PartyTitleLookupQuery = gql`
    query PartyTitleLookup {
        lookup{
            partyTitle{
                id,
                description
            }
        }
    }
`;