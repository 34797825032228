/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import { Autocomplete, RenderInputParams } from '@material-ui/lab';
import { TextField, CircularProgress } from '@material-ui/core';
import match from 'autosuggest-highlight/match';
import parse from 'autosuggest-highlight/parse';
import { IAutoCompleteItem } from '../../typings/autoComplete';
import { useRiskLevel } from '../../hooks/useRiskLevel';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            width: '100%',
        },
    }),
);

interface RiskLevelSelectorProps {
    disabled?: boolean;
    required?: boolean;
    multiple?: boolean;
    // tslint:disable-next-line: no-any
    value?: IAutoCompleteItem | any; // value only available in single select
    error?: boolean;
    onSelection?: (value: IAutoCompleteItem | IAutoCompleteItem[], name: string) => void;
    className?: string;
    helperText?: string;
    name?: string;
    label?: string;
    disablePortal?: boolean;
    disableCloseOnSelect?: boolean;
    onBlur?: React.FocusEventHandler<HTMLDivElement>;
}

// interface RiskLevelSelectorState {
//     options: IAutoCompleteItem[];
// }

export const RiskLevelSelector: React.FC<RiskLevelSelectorProps> = (props) => {
    const classes = useStyles();

    // const [state, setState] = useState<RiskLevelSelectorState>({
    //     options: [],
    // });

    const riskLevelQuery = useRiskLevel('cache-first');

    // tslint:disable-next-line: no-any
    const handleChange = (event: React.ChangeEvent<{}>, value: any) => {
        if (props.onSelection) {
            props.onSelection(value, props.name ? props.name : 'riskLevel');
        }
    };

    const getInputProps = (params: RenderInputParams, isLoading?: boolean) => {
        return {
            ...params.InputProps,
            endAdornment: (
                <React.Fragment>
                    {isLoading ? <CircularProgress color="primary" size={20} /> : null}
                    {params.InputProps.endAdornment}
                </React.Fragment>
            ),
        };
    };

    const options: IAutoCompleteItem[] = riskLevelQuery.data
        ? riskLevelQuery.data.lookup.riskLevel.map((risk) => {
              return {
                  ...risk,
                  value: risk.guidId,
                  label: risk.description,
              };
          })
        : [];

    return (
        <div className={`${classes.root} ${props.className}`}>
            {props.multiple ? (
                <Autocomplete
                    value={props.value}
                    multiple={true}
                    loading={riskLevelQuery.loading}
                    disabled={props.disabled}
                    onChange={handleChange}
                    options={options}
                    disableCloseOnSelect={props.disableCloseOnSelect}
                    disablePortal={props.disablePortal} // to Stop the dropdown select from closing the form
                    // tslint:disable-next-line: jsx-no-lambda
                    getOptionLabel={(option: IAutoCompleteItem) => option.label}
                    getOptionSelected={(option, value) => value.value === option.value}
                    onBlur={props.onBlur}
                    // tslint:disable-next-line: jsx-no-lambda
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            label={props.label}
                            variant="standard"
                            fullWidth={true}
                            margin="none"
                            required={props.required}
                            InputProps={getInputProps(params, riskLevelQuery.loading)}
                            error={props.error}
                            helperText={props.helperText}
                        />
                    )}
                    // tslint:disable-next-line: jsx-no-lambda
                    renderOption={(option, { inputValue }) => {
                        const matches = match(option.label, inputValue);
                        const parts = parse(option.label, matches);

                        return (
                            <div>
                                {parts.map((part, index) => (
                                    <span key={index} style={{ fontWeight: part.highlight ? 700 : 400 }}>
                                        {part.text}
                                    </span>
                                ))}
                            </div>
                        );
                    }}
                />
            ) : (
                <Autocomplete
                    value={props.value}
                    loading={riskLevelQuery.loading}
                    disabled={props.disabled}
                    onChange={handleChange}
                    options={options}
                    disableCloseOnSelect={props.disableCloseOnSelect}
                    disablePortal={props.disablePortal} // to Stop the dropdown select from closing the form
                    // tslint:disable-next-line: jsx-no-lambda
                    getOptionLabel={(option: IAutoCompleteItem) => option.label}
                    getOptionSelected={(option, value) => value.value === option.value}
                    onBlur={props.onBlur}
                    // tslint:disable-next-line: jsx-no-lambda
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            label={props.label}
                            variant="standard"
                            fullWidth={true}
                            margin="none"
                            required={props.required}
                            InputProps={getInputProps(params, riskLevelQuery.loading)}
                            error={props.error}
                            helperText={props.helperText}
                        />
                    )}
                    // tslint:disable-next-line: jsx-no-lambda
                    renderOption={(option, { inputValue }) => {
                        const matches = match(option.label, inputValue);
                        const parts = parse(option.label, matches);

                        return (
                            <div>
                                {parts.map((part, index) => (
                                    <span key={index} style={{ fontWeight: part.highlight ? 700 : 400 }}>
                                        {part.text}
                                    </span>
                                ))}
                            </div>
                        );
                    }}
                />
            )}
        </div>
    );
};
