/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
// import { name, random } from 'faker';
import gql from 'graphql-tag';
import { FetchPolicy } from 'apollo-client';
import { TextField, CircularProgress } from '@material-ui/core';
import { IAutoCompleteItem } from '../../typings/autoComplete';
import { showNotification } from '../../App';
import { client } from '../..';
import { InfiniteAutocomplete } from '../../components/InfiniteAutocomplete';
import { RenderInputParams } from '@material-ui/lab';
import { useDebouncedCallback } from 'use-debounce/lib';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
        minWidth: '200px',
    },
    textField: {
        width: '100%',
    },
    iconSelected: {
        width: 17,
        height: 17,
        marginRight: 5,
        marginLeft: -2,
      },
      color: {
        width: 14,
        height: 14,
        flexShrink: 0,
        borderRadius: 3,
        marginRight: 8,
        marginTop: 2,
      },
      text: {
        flexGrow: 1,
      },
      close: {
        opacity: 0.6,
        width: 18,
        height: 18,
      },
      renderWrapper: {
          padding: '15px 0'
      },
  })
);

interface ReferredToSelectorProps {
    isStaffOnly?: boolean;
    multiple?: boolean;
    label?: string;
    name: string;
    // tslint:disable-next-line: no-any
    value?: IAutoCompleteItem | any; // value only available in single select
    onSelection?: (value: IAutoCompleteItem | IAutoCompleteItem[], name: string) => void;
    id?: string;
    required?: boolean;
    helperText?: string;
    error?: boolean;
    className?: string;
    disablePortal?: boolean;
}

interface ReferredToSelectorState {
    hasNextPage: boolean;
    isNextPageLoading: boolean;
    offset: number;
    items: IAutoCompleteItem[];
    filter: string;
}

export const ReferredToSelector: React.FC<ReferredToSelectorProps> = props => {

    const classes = useStyles();
    const loadBlockLimit = 20;

    const [delayedonInputChange] = useDebouncedCallback(
        // function
        (event: React.ChangeEvent<{}>, value: string, reason: 'input' | 'reset' | 'clear') => {
            let isClear = false;
            let offset = 0;
            let filter = '';

            if (reason === 'input') {
                filter = value;
                isClear = true;

                fetchData(filter, offset, isClear);
            }
        },
        // delay in ms
        500
    );
    
    const [state, setState] = useState<ReferredToSelectorState>({
        hasNextPage: true,
        isNextPageLoading: false,
        offset: 0,
        items: [],
        filter: ''
    });

    useEffect(() => {
        fetchData('', 0, false);
    // tslint:disable-next-line: align
    }, []);

    const fetchData = (filter: string, offset: number, isClear: boolean) => {

        setState((prevState) => {
            return {
                ...prevState,
                isNextPageLoading: true,
                offset: offset,
                filter: filter
            };
        });

        const partyQueryParams: PartyQueryParams = {
            filter: filter.length > 0 ? filter : null,
            first: loadBlockLimit,
            offset: offset,
            includeOrganisation: true,
            includeIndividual: true,
            includeStaff: null,
        };

        retrievePartyData(
            partyQueryParams,
            true, // this.state.isRefresh, -- Since we are updating and creating, we do not fetch cached data
            // tslint:disable-next-line: no-console
            (data) => onRetrieveUserList(data, filter, isClear),
            // tslint:disable-next-line:no-any
            function (reason: any): void {
                showNotification(null, reason, 'error');

                setState((prevState) => {
                    return {
                        ...prevState,
                        isNextPageLoading: false,
                    };
                });
            }
        );
    };

    const onRetrieveUserList = (data: PartyData, filter: string, isClear: boolean) => {

        const nameSummaryData = data.party.nameSummaries.nameSummary;
        const recordCount = data.party.nameSummaries.recordCount;

        // tslint:disable-next-line: no-any
        let itemsList: any = [...state.items];

        if (!isClear) {
            itemsList = [...state.items];
        } else {
            itemsList = [];
        }

        if (state.items.length === 0) {
            itemsList = nameSummaryData.map( (source: NameSummary) => ({value: source.guidID.toString(), label: source.name }));
        } else {
            for (const nameSummary of nameSummaryData) {
                // tslint:disable-next-line: no-any
                const isExists = state.items.filter((item: IAutoCompleteItem) => 
                    item.value.toString().toLowerCase().includes(nameSummary.guidID.toString().toLowerCase())).length;

                if (isExists === 0) {
                    itemsList.push({
                        label: nameSummary.name,
                        value: nameSummary.guidID.toString()
                    });
                }
            }
        }

        setState((prevState) => {
            return {
                ...prevState,
                filter: filter,
                isNextPageLoading: false,
                items: itemsList,
                offset: state.offset + loadBlockLimit,
                hasNextPage: nameSummaryData.length !== 0 && (state.offset + loadBlockLimit) < recordCount,
            };
        });
    };

    // tslint:disable-next-line: no-any
    const loadNextPage = (...args: any) => {
        if (state.hasNextPage && !state.isNextPageLoading) {
            fetchData(state.filter, state.offset, false);
        }
    };

    // const onInputChange = (event: React.ChangeEvent<{}>, value: string, reason: 'input' | 'reset' | 'clear') => {
    //     fetchData(value, 0);
    // };

    const getInputProps = (params: RenderInputParams, isLoading?: boolean) => {
        return  ({   
            ...params.InputProps,
            endAdornment: (
                <React.Fragment>
                  {isLoading ? <CircularProgress color="primary" size={20} /> : null}
                  {params.InputProps.endAdornment}
                </React.Fragment>
              ),     
         });
    };

    const onClose = (event: React.ChangeEvent<{}>) => {

        setState((prevState) => {
            return {
                ...prevState,
                isNextPageLoading: false,
                hasNextPage: true,
                filter: '',
                offset: 0,
                items: []
            };
        });
    };

    const onOpen = (event: React.ChangeEvent<{}>) => {
        // tslint:disable-next-line: no-console
        fetchData(state.filter, 0, true);
    };

    return (
        <div className={`${classes.root}`}>
            <InfiniteAutocomplete
                id={props.id}
                itemSize={40}
                loading={state.isNextPageLoading}
                disablePortal={props.disablePortal}
                name={props.name}
                isMultiple={props.multiple}
                value={props.value}
                hasNextPage={state.hasNextPage}
                isNextPageLoading={state.isNextPageLoading}
                items={state.items}
                loadNextPage={loadNextPage}
                // disableCloseOnSelect={true}
                className={classes.textField}
                disableListWrap={true}
                onSelection={props.onSelection}
                onClose={onClose}
                onOpen={onOpen}
                getOptionLabel={(option: IAutoCompleteItem) => option.label}
                onInputChange={delayedonInputChange}
                disabledFilterOptions={true}
                renderInput={params => (
                    <TextField
                        {...params}
                        variant="standard"
                        label={props.label}
                        fullWidth={true}
                        margin="none" 
                        required={props.required}
                        InputProps={getInputProps(params, state.isNextPageLoading)}
                        error={props.error}      
                        helperText={props.helperText}  
                    />
                )}
            />
        </div>
    );
};

export function retrievePartyData(
    query: PartyQueryParams,
    refreshData: boolean,
    onSuccess: (data: PartyData) => void,
    // tslint:disable-next-line:no-any
    onError: (reason: any) => void
): void {
    var fetchPolicy: FetchPolicy = refreshData === true ? 'network-only' : 'cache-first';
    client
        .query({
            query: PartyQuery,
            variables: {
                filter: query.filter,
                first: query.first,
                offset: query.offset,
                includeIndividual: query.includeIndividual,
                includeOrganisation: query.includeOrganisation,
                includeStaff: query.includeStaff
            },
            fetchPolicy: fetchPolicy,
        })
        // tslint:disable-next-line:no-any
        .then((results: { data: any }) => {
            onSuccess(results.data);
        })
        // tslint:disable-next-line:no-any
        .catch((reason: any) => {
            onError(reason);
        });
}

export interface PartyQueryParams {
    offset: number | null;
    first: number | null;
    filter: string | null;
    includeIndividual: Boolean | null;
    includeOrganisation: Boolean | null;
    includeStaff: Boolean | null;
}

export interface PartyData {
    loading?: boolean;
    networkStatus?: number;
    party: Party;
}

export interface Party {
    nameSummaries: NameSummaries;
}

export interface NameSummaries {
    recordCount: number;
    nameSummary: NameSummary[];
}

export interface NameSummary {
    id:           number;
    guidID:       string;
    name:         string;
    address:      string;
}

const PartyQuery = gql`
    query Party(
        $offset: Int,
        $first: Int,
        $filter: String,
        $includeIndividual: Boolean,
        $includeOrganisation: Boolean,
        $includeStaff: Boolean,
    ){
        party{
            nameSummaries(
                offset: $offset,
                first: $first,
                filter: $filter,
                includeIndividual: $includeIndividual,
                includeOrganisation: $includeOrganisation,
                includeStaff: $includeStaff,
            ){
                recordCount
                nameSummary{
                    id,
                    guidID,
                    name
                    address
                }
            }
        }
    }
`;