/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from 'react';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
// import { name, random } from 'faker';
import gql from 'graphql-tag';
import { FetchPolicy } from 'apollo-client';
import { TextField, CircularProgress } from '@material-ui/core';
// import { IAutoCompleteItem } from '../../typings/autoComplete';
import { showNotification } from '../../App';
import { client } from '../..';
import { InfiniteAutocomplete } from '../../components/InfiniteAutocomplete';
import { RenderInputParams } from '@material-ui/lab';
import PersonOutlinedIcon from '@material-ui/icons/PersonOutlined';
import { useDebouncedCallback } from 'use-debounce/lib';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
        minWidth: '200px',
    },
    textField: {
        width: '100%',
    },
    iconSelected: {
        width: 17,
        height: 17,
        marginRight: 5,
        marginLeft: -2,
      },
      color: {
        width: 14,
        height: 14,
        flexShrink: 0,
        borderRadius: 3,
        marginRight: 8,
        marginTop: 2,
      },
      text: {
        flexGrow: 1,
      },
      close: {
        opacity: 0.6,
        width: 18,
        height: 18,
      },
      renderWrapper: {
          padding: '15px 0'
      },
      customRenderWrapper: {
        display: 'flex',
        margin: '0 -10px',
        width: '100%',
        '& > div': {
            padding: '0 10px',
            fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
            fontWeight: 400,
        },
        '& .icon-wrapper': {
            display: 'flex',
            alignItems: 'center',
        },
        '& .other': {
            flex: 1,
            display: 'flex',
            flexFlow: 'column',
            justifyContent: 'center',
            '& .primary': {
                fontSize: '1rem',
                lineHeight: 1.5,
                letterSpacing: '0.00938em',
                // wordBreak: 'break-all',
                // wordWrap: 'break-word',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
                display: 'flex',
                alignItems: 'center',
                gap: '5px',
            },
            '& .secondary': {
                color: 'rgba(0, 0, 0, 0.54)',
                fontSize: '0.875rem',
                lineHeight: 1.43,
                letterSpacing: '0.01071em',
                // wordBreak: 'break-all',
                // wordWrap: 'break-word',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
            },
            '& .dataSource': {
                fontStyle: 'italic',
            }
        }
      },
      isDataFromSource: {
        fontSize: '13px',
      }
  })
);

interface IndividualSelectorProps {
    isStaffOnly?: boolean;
    multiple?: boolean;
    label?: string;
    name: string;
    // tslint:disable-next-line: no-any
    value?: IndividualNameSummary | any; // value only available in single select
    onSelection?: (value: IndividualNameSummary | IndividualNameSummary[], name: string) => void;
    id?: string;
    required?: boolean;
    helperText?: string;
    error?: boolean;
    className?: string;
    disablePortal?: boolean;
    enableSecondary?: boolean;
    onBlur?: React.FocusEventHandler<HTMLDivElement>;

    includeIndividual?: boolean;
    includeOrganisation?: boolean;
    includeStaff?: boolean;
    isClientsOnly?: boolean;
    tagsList?: string;
    stateList?: string;
    countryList?: string;
    businessTypeList?: string;
    eventsAndCampaignGuid?: string;
    refresh?: boolean;
}

interface IndividualSelectorState {
    hasNextPage: boolean;
    isNextPageLoading: boolean;
    offset: number;
    items: IndividualNameSummary[];
    filter: string;
}

export const IndividualSelector: React.FC<IndividualSelectorProps> = props => {

    // console.log('eventsAndCampaignGuid', props.eventsAndCampaignGuid);

    const classes = useStyles();
    const loadBlockLimit = 20;
    
    const [state, setState] = useState<IndividualSelectorState>({
        hasNextPage: true,
        isNextPageLoading: false,
        offset: 0,
        items: [],
        filter: ''
    });

    const [delayedonInputChange] = useDebouncedCallback(
        // function
        (event: React.ChangeEvent<{}>, value: string, reason: 'input' | 'reset' | 'clear') => {

            let isClear = false;
            let offset = 0;
            let filter = '';

            if (reason === 'input') {
                filter = value;
                isClear = true;

                fetchData(filter, offset, isClear);
            }
        },
        // delay in ms
        500
    );

    const fetchData = (filter: string, offset: number, isClear: boolean) => {

        setState((prevState) => {
            return {
                ...prevState,
                isNextPageLoading: true,
            };
        });

        const partyQueryParams: PartyQueryParams = {
            filter: filter,
            first: loadBlockLimit,
            offset: offset,
            includeOrganisation: props.includeOrganisation ?? null,
            includeIndividual: props.includeIndividual ?? true,
            includeStaff: props.includeStaff ?? null,
            isClientsOnly: props.isClientsOnly,
            eventsAndCampaignGuid: props.eventsAndCampaignGuid
        };

        retrievePartyData(
            partyQueryParams,
            props.refresh ?? false,
            // tslint:disable-next-line: no-console
            (data) => onRetrieveData(data, filter, offset, isClear),
            // tslint:disable-next-line:no-any
            function (reason: any): void {
                showNotification(null, reason, 'error');

                setState((prevState) => {
                    return {
                        ...prevState,
                        isNextPageLoading: false,
                    };
                });
            }
        );
    };

    const onRetrieveData = (data: PartyData, filter: string, offset: number, isClear: boolean) => {

        const nameSummaryData = data.party.nameSummaries.nameSummary;
        const recordCount = data.party.nameSummaries.recordCount;

        // tslint:disable-next-line: no-any
        let itemsList: IndividualNameSummary[] = [];
        let items = state.items;
        
        if (!isClear) {
            itemsList = [...state.items];
        } else {
            items = [];
        }

        if (items && items.length === 0) {
            itemsList = nameSummaryData.map( (source: IndividualNameSummary) => (
                {
                    value: source.guidID.toString(),
                    label: source.name,
                    id: source.id,
                    guidID: source.guidID.toString(), 
                    name: source.name,
                    firstName: source.firstName,
                    lastName: source.lastName,
                    emailAddress: source.emailAddress,
                    mobile: source.mobile,
                    phone: source.phone,
                    gender: source.gender,
                    organisationGuid: source.organisationGuid,
                    organisationName: source.organisationName,
                    nameId: source.nameId,
                    addressName: source.addressName,
                    floor: source.floor,
                    number: source.number,
                    street: source.street,
                    city: source.city,
                    state: source.state,
                    postCode: source.postCode,
                    country: source.country,
                    isFromDataSource: source.isFromDataSource,
                    title: source.title
                }));
        } else {
            for (const nameSummary of nameSummaryData) {
                // tslint:disable-next-line: no-any
                const isExists = items.filter((item: any) => item.value === nameSummary.guidID).length;

                // const isExists = items.filter((item: IndividualNameSummary) => 
                //     item.guidID.toString().toLowerCase().includes(nameSummary.guidID.toLowerCase())).length;

                if (isExists === 0) {
                    itemsList.push({
                        value: nameSummary.guidID.toString(),
                        label: nameSummary.name,
                        id: nameSummary.id,
                        guidID: nameSummary.guidID.toString(), 
                        name: nameSummary.name,
                        firstName: nameSummary.firstName,
                        lastName: nameSummary.lastName,
                        emailAddress: nameSummary.emailAddress,
                        mobile: nameSummary.mobile,
                        phone: nameSummary.phone,
                        gender: nameSummary.gender,
                        organisationGuid: nameSummary.organisationGuid,
                        organisationName: nameSummary.organisationName,
                        nameId: nameSummary.nameId,
                        addressName: nameSummary.addressName,
                        floor: nameSummary.floor,
                        number: nameSummary.number,
                        street: nameSummary.street,
                        city: nameSummary.city,
                        state: nameSummary.state,
                        postCode: nameSummary.postCode,
                        country: nameSummary.country,
                        isFromDataSource: nameSummary.isFromDataSource,
                        title: nameSummary.title
                    });
                }
            }
        }

        setState((prevState) => {
            return {
                ...prevState,
                filter: filter,
                isNextPageLoading: false,
                items: itemsList,
                offset: offset + loadBlockLimit,
                hasNextPage: nameSummaryData.length !== 0 && (offset + loadBlockLimit) < recordCount,
            };
        });

    };

    // tslint:disable-next-line: no-any
    const loadNextPage = (...args: any) => {
        if (state.hasNextPage && !state.isNextPageLoading) {
            fetchData(state.filter, state.offset, false);
        }
    };

    const getInputProps = (params: RenderInputParams, loading?: boolean) => {
        return  ({   
            ...params.InputProps,
            endAdornment: (
                <React.Fragment>
                  {loading ? <CircularProgress color="primary" size={20} /> : null}
                  {params.InputProps.endAdornment}
                </React.Fragment>
              ),     
         });
    };

    const onClose = (event: React.ChangeEvent<{}>) => {

        setState((prevState) => {
            return {
                ...prevState,
                isNextPageLoading: false,
                hasNextPage: true,
                filter: '',
                offset: 0,
                items: []
            };
        });
    };

    const onOpen = (event: React.ChangeEvent<{}>) => {
        
        fetchData(state.filter, 0, true);
    };

    // tslint:disable-next-line: no-any
    const onSelection = (selection: any, name: string) => {

        if (props.onSelection) {
            props.onSelection(selection, name);
        }
    };

    return (
        <div className={`${classes.root}`}>
            <InfiniteAutocomplete
                key={props.id}
                itemSize={props.enableSecondary ? 90 : 55}
                loading={state.isNextPageLoading}
                name={props.name}
                disablePortal={props.disablePortal}
                disableCloseOnSelect={false}                
                isMultiple={props.multiple}
                value={props.value}
                hasNextPage={state.hasNextPage}
                isNextPageLoading={state.isNextPageLoading}
                items={state.items}
                // inputValue={props.value}
                options={state.items}
                isLoading={state.isNextPageLoading}
                loadNextPage={loadNextPage}                
                className={classes.textField}
                disableListWrap={true}
                onSelection={onSelection}
                onClose={onClose}
                onOpen={onOpen}
                getOptionLabel={option => typeof option === 'string' ? option : option.label}
                onInputChange={delayedonInputChange}
                onBlur={props.onBlur}
                // tslint:disable-next-line: no-any
                renderInput={(params: any) => (
                    <TextField
                        {...params}
                        variant="standard"
                        label={props.label}
                        fullWidth={true}
                        margin="none" 
                        required={props.required}
                        InputProps={getInputProps(params, state.isNextPageLoading)}
                        error={props.error}      
                        helperText={props.helperText}  
                    />
                )}
                renderOption={(option, { selected }) => {
                    return (
                        <div className={classes.customRenderWrapper}>
                            <div className="icon-wrapper">
                                <PersonOutlinedIcon fontSize="large"/>
                            </div>
                            <div className="other">
                                <div className="primary">
                                    {option.label}
                                </div>
                                {!!props.enableSecondary && (
                                    <>
                                        <div className="secondary">{option.emailAddress}</div>
                                        <div className="secondary">{option.phone}</div>
                                    </>
                                )}
                                {!option.isFromDataSource ? (
                                   <div className="secondary dataSource">
                                        Entered
                                   </div>
                                ) : null}
                            </div>
                        </div>
                    );
                }}
            />
        </div>
    );
};

export function retrievePartyData(
    query: PartyQueryParams,
    refreshData: boolean,
    onSuccess: (data: PartyData) => void,
    // tslint:disable-next-line:no-any
    onError: (reason: any) => void
): void {
    var fetchPolicy: FetchPolicy = refreshData === true ? 'network-only' : 'cache-first';
    client
        .query({
            query: PartyQuery,
            variables: {
                filter: query.filter,
                first: query.first,
                offset: query.offset,
                includeIndividual: query.includeIndividual,
                includeOrganisation: query.includeOrganisation,
                includeStaff: query.includeStaff,
                isClientsOnly: query.isClientsOnly,
                eventsAndCampaignGuid: query.eventsAndCampaignGuid
            },
            fetchPolicy: fetchPolicy,
        })
        // tslint:disable-next-line:no-any
        .then((results: { data: any }) => {
            onSuccess(results.data);
        })
        // tslint:disable-next-line:no-any
        .catch((reason: any) => {
            onError(reason);
        });
}

export interface PartyQueryParams {
    offset: number | null;
    first: number | null;
    filter: string | null;
    includeIndividual: boolean | null;
    includeOrganisation: boolean | null;
    includeStaff: boolean | null;
    isClientsOnly?: boolean;
    tagsList?: String;
    stateList?: String;
    countryList?: String;
    businessTypeList?: String;
    eventsAndCampaignGuid?: string;
}

export interface PartyData {
    loading?: boolean;
    networkStatus?: number;
    party: Party;
}

export interface Party {
    nameSummaries: NameSummaries;
}

export interface NameSummaries {
    recordCount: number;
    nameSummary: IndividualNameSummary[];
}

export interface IndividualNameSummary {
    value: string;
    label: string;
    id: number | null;
    guidID: string;
    name: string;
    firstName: string | null;
    lastName: string | null;
    emailAddress: string | null;
    mobile: string | null;
    phone: string | null;
    gender: string | null;
    organisationName: string | null;
    organisationGuid: string | null;
    nameId: number | null;
    addressName: string | null;
    floor: string | null;
    number: string | null;
    street: string | null;
    city: string | null;
    state: string | null;
    postCode: string | null;
    country: string | null;
    isFromDataSource: boolean | null;
    title: string | null;
}

const PartyQuery = gql`
    query Party(
        $offset: Int,
        $first: Int,
        $filter: String,
        $includeIndividual: Boolean,
        $includeOrganisation: Boolean,
        $includeStaff: Boolean,

        $isClientsOnly: Boolean,
        $tagsList: String,
        $stateList: String,
        $countryList: String,
        $businessTypeList: String,
        $eventsAndCampaignGuid: String
    ){
        party{
            nameSummaries(
                offset: $offset, 
                first: $first, 
                filter: $filter, 
                isClientsOnly: $isClientsOnly, 
                includeStaff: $includeStaff, 
                includeIndividual: $includeIndividual, 
                includeOrganisation: $includeOrganisation,
                tagsList: $tagsList,
                stateList: $stateList,
                countryList: $countryList,
                businessTypeList: $businessTypeList,
                eventsAndCampaignGuid: $eventsAndCampaignGuid
            ){
                recordCount
                nameSummary{
                    id,
                    guidID,
                    name,
                    firstName,
                    lastName,
                    emailAddress,
                    mobile,
                    phone,
                    gender,
                    organisationName,
                    organisationGuid,
                    nameId
                    addressName 
                    floor
                    number
                    street
                    city
                    state
                    postCode
                    country
                    isFromDataSource
                    title
                }
            }
        }
    }
`;
