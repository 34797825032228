import React, { useState } from 'react';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
// import { name, random } from 'faker';
import gql from 'graphql-tag';
import { FetchPolicy } from 'apollo-client';
import { TextField, CircularProgress, ListItem, ListItemText } from '@material-ui/core';
import { RenderInputParams } from '@material-ui/lab';
import { useDebouncedCallback } from 'use-debounce/lib';
import { showNotification } from '../../App';
import { IAutoCompleteItem } from '../../typings/autoComplete';
import { InfiniteAutocomplete } from '../../components/InfiniteAutocomplete';
import { client } from '../..';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
        minWidth: '200px',
    },
    textField: {
        width: '100%',
    },
    iconSelected: {
        width: 17,
        height: 17,
        marginRight: 5,
        marginLeft: -2,
      },
      color: {
        width: 14,
        height: 14,
        flexShrink: 0,
        borderRadius: 3,
        marginRight: 8,
        marginTop: 2,
      },
      text: {
        flexGrow: 1,
      },
      close: {
        opacity: 0.6,
        width: 18,
        height: 18,
      },
      renderWrapper: {
          padding: '15px 0'
      },
      customRenderWrapper: {
        display: 'flex',
        margin: '0 -10px',
        width: '100%',
        '& > div': {
            padding: '0 10px',
            fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
            fontWeight: 400,
        },
        '& .icon-wrapper': {
            display: 'flex',
            alignItems: 'center',
        },
        '& .other': {
            flex: 1,
            display: 'flex',
            flexFlow: 'column',
            '& .primary': {
                fontSize: '1rem',
                lineHeight: 1.5,
                letterSpacing: '0.00938em',
            },
            '& .secondary': {
                color: 'rgba(0, 0, 0, 0.54)',
                fontSize: '0.875rem',
                lineHeight: 1.43,
                letterSpacing: '0.01071em',
            },
            '& .third': {
                color: 'rgba(0, 0, 0, 0.54)',
                fontSize: '0.675rem',
                lineHeight: 1.43,
                letterSpacing: '0.01071em',
                fontWeight: 600
            }
        }
      }
  })
);

interface EnquiryAutocompleteSelectorProps {
    isStaffOnly?: boolean;
    multiple?: boolean;
    isExternal?: boolean;
    label?: string;
    name: string;
    required?: boolean;
    // tslint:disable-next-line: no-any
    value?: IAutoCompleteItem | any; // value only available in single select
    onSelection?: (value: IAutoCompleteItem | IAutoCompleteItem[], name: string) => void;
    id?: string;
    error?: boolean;
    disablePortal?: boolean;
    className?: string;
    helperText?: React.ReactNode;
    onBlur?: React.FocusEventHandler<HTMLDivElement>;
    variant?: 'filled' | 'standard' | 'outlined';
    disabled?: boolean;
    disableCloseOnSelect?: boolean;
}

interface EnquiryAutocompleteSelectorState {
    hasNextPage: boolean;
    isNextPageLoading: boolean;
    offset: number;
    filter: string;
    // tslint:disable-next-line: no-any
    items: any;
}

export const EnquiryAutocompleteSelector: React.FC<EnquiryAutocompleteSelectorProps> = props => {

    const classes = useStyles();
    const loadBlockLimit = 20;
    
    const [state, setState] = useState<EnquiryAutocompleteSelectorState>({
        hasNextPage: true,
        isNextPageLoading: false,
        offset: 0,
        filter: '',
        items: [],
    });

    const [delayedonInputChange] = useDebouncedCallback(
        // function
        (event: React.ChangeEvent<{}>, value: string, reason: 'input' | 'reset' | 'clear') => {

            let isClear = false;
            let offset = 0;
            let filter = '';

            if (reason === 'input') {
                filter = value;
                isClear = true;

                fetchData(filter, offset, isClear);
            }
        },
        // delay in ms
        500
    );
    
    const fetchData = (filter: string, offset: number, isClear: boolean) => {

        setState((prevState) => {
            return {
                ...prevState,
                isNextPageLoading: true
            };
        });

        const enquirySummaryQueryParams: EnquirySummaryQueryParams = {
            filter: filter,
            first: loadBlockLimit,
            offset: offset,
            isIncludeCurrent: true,
            isIncludeClosed: true,
            isMyEnquiriesOnly: false,
            groupBy: null,
            groupByValue: null,
            followUpDue: null,
            newEnquiry: null,
            assignedTo: null,
            areaOfLaw: null,
            teamList: null,
            isKeyOpportunity: false,
            enquiryQuality: null,
            enquiryLikelihoodToProceed: null,
            meetingWith: null,
            status: null,
        };

        retrieveEnquirySummaryData(
            enquirySummaryQueryParams,
            true, // this.state.isRefresh, -- Since we are updating and creating, we do not fetch cached data
            // tslint:disable-next-line: no-console
            (data) => onRetrieveSummaryList(data, filter, offset, isClear),
            // tslint:disable-next-line:no-any
            function (reason: any): void {
                showNotification(null, reason, 'error');

                setState((prevState) => {
                    return {
                        ...prevState,
                        isNextPageLoading: false,
                    };
                });
            }
        );
    };

    const onRetrieveSummaryList = (data: EnquiryData, filter: string, offset: number, isClear: boolean) => {

        const summaryData = data.enquiry.summaryList.summaries;
        const recordCount = data.enquiry.summaryList.recordCount;

        // tslint:disable-next-line: no-any
        let itemsList: any = [];

        let items = state.items;

        if (!isClear) {
            itemsList = [...state.items];
        } else {
            items = [];
        }

        if (items && items.length === 0) {
            itemsList = summaryData.map( (source: Summary) => ({
                value: source.guid, 
                label: source.name,
                email: source.email,
            }));
        } else {
            for (const summary of summaryData) {
                // tslint:disable-next-line: no-any
                const isExists = items.filter((item: any) => item && item.email !== null && item.email.includes(summary.email)).length;

                if (isExists === 0) {
                    itemsList.push({
                        value: summary.guid, 
                        label: summary.name,
                        email: summary.email
                    });
                }
            }
        }

        setState((prevState) => {
            return {
                ...prevState,
                filter: filter,
                isNextPageLoading: false,
                items: itemsList,
                offset: offset + loadBlockLimit,
                hasNextPage: summaryData.length !== 0 && (offset + loadBlockLimit) < recordCount,
            };
        });

    };

    // tslint:disable-next-line: no-any
    const loadNextPage = (...args: any) => {
        
        if (state.hasNextPage && !state.isNextPageLoading) {
            fetchData(state.filter, state.offset, false);
        }
    };

    const onClose = (event: React.ChangeEvent<{}>) => {

        setState((prevState) => {
            return {
                ...prevState,
                isNextPageLoading: false,
                hasNextPage: true,
                filter: '',
                offset: 0,
                items: []
            };
        });
    };

    const onOpen = (event: React.ChangeEvent<{}>) => {
        
        fetchData(state.filter, 0, true);
    };

    const onSelection = (selection: IAutoCompleteItem, name: string) => {

        if (props.onSelection) {
            props.onSelection(selection, name);
        }
    };

    const getInputProps = (params: RenderInputParams, isLoading?: boolean) => {
        return  ({   
            ...params.InputProps,
            endAdornment: (
                <React.Fragment>
                  {isLoading ? <CircularProgress color="primary" size={20} /> : null}
                  {params.InputProps.endAdornment}
                </React.Fragment>
              ),     
         });
    };

    return (
        <div className={`${classes.root} ${props.className}`}>
            <InfiniteAutocomplete
                id={props.id}
                itemSize={70}
                loading={state.isNextPageLoading}
                disablePortal={props.disablePortal}
                name={props.name}
                isMultiple={props.multiple}
                value={props.value}
                hasNextPage={state.hasNextPage}
                isNextPageLoading={state.isNextPageLoading}
                items={state.items}
                loadNextPage={loadNextPage}
                // disableCloseOnSelect={true}
                className={classes.textField}
                disableListWrap={true}
                onSelection={onSelection}
                onClose={onClose}
                onOpen={onOpen}
                getOptionLabel={(option: IAutoCompleteItem) => option.label}
                getOptionSelected={(option, value) => value.value === option.value}
                onInputChange={delayedonInputChange}
                onBlur={props.onBlur}
                disabled={props.disabled}
                disableCloseOnSelect={props.disableCloseOnSelect}
                renderOption={(option, { selected }) => {
                    return (
                        <ListItem>
                            <ListItemText 
                                primary={option.label} 
                                secondary={option.email}
                            />
                        </ListItem>
                    );
                }}
                renderInput={params => (
                    <TextField
                        {...params}
                        variant={props.variant}
                        label={props.label}
                        required={props.required}
                        error={props.error}
                        fullWidth={true}
                        InputProps={getInputProps(params, state.isNextPageLoading)}
                        helperText={props.helperText}
                    />
                )}
            />
        </div>
    );
};

export interface EnquirySummaryQueryParams {
    offset: number | null; 
    first: number | null;
    filter?: string | null;
    isIncludeCurrent: boolean | null;
    isIncludeClosed: boolean | null;
    isMyEnquiriesOnly: boolean | null;
    sortColumn?: string | null; 
    sortDirection?: string | null;
    groupBy: string | null;
    groupByValue: string | null;
    followUpDue: string | null;
    newEnquiry: string | null;
    assignedTo: string | null;
    areaOfLaw: string | null;
    teamList: string | null;
    isKeyOpportunity: boolean;
    enquiryQuality: string | null;
    enquiryLikelihoodToProceed: string | null;
    meetingWith: string | null;
    status: string | null;
}

export interface EnquiryData {
    loading?: boolean;
    networkStatus?: number;
    enquiry: Enquiry;
}
export interface Enquiry {
    summaryList: SummaryList;
}

export interface SummaryList {
    recordCount:             number;
    actualRowCountForSearch: number;
    summaries:               Summary[];
}

export interface Summary {
    id:        number;
    guid:      string;
    name:      string;
    firstName: string;
    lastName:  string;
    email:     null | string;
}

export function retrieveEnquirySummaryData(query: EnquirySummaryQueryParams,
                                           refreshData: boolean,
                                           onSuccess: (data: EnquiryData) => void,
                                           // tslint:disable-next-line:no-any
                                           onError: (reason: any) => void)
                                           : void {
    var fetchPolicy: FetchPolicy = refreshData === true ? 'network-only' : 'cache-first';
    client.query({
        query: EnquirySummaryListData,
        variables: {
            offset: query.offset,
            first: query.first,
            filter: query.filter,
            isIncludeCurrent: query.isIncludeCurrent,
            isIncludeClosed: query.isIncludeClosed,
            isMyEnquiriesOnly: query.isMyEnquiriesOnly,
            sortColumn: query.sortColumn,
            sortDirection: query.sortDirection,
            groupBy: query.groupBy,
            groupByValue: query.groupByValue,
            followUpDue: query.followUpDue,
            newEnquiry: query.newEnquiry,
            assignedTo: query.assignedTo,
            areaOfLaw: query.areaOfLaw,
            teamList: query.teamList,
            isKeyOpportunity: query.isKeyOpportunity,
            enquiryQuality: query.enquiryQuality,
            enquiryLikelihoodToProceed: query.enquiryLikelihoodToProceed,
            meetingWith: query.meetingWith,
            status: query.status
        },
        fetchPolicy: fetchPolicy
    })
    // tslint:disable-next-line:no-any
    .then((results: { data: any; }) => {
        onSuccess(results.data);
    })
    // tslint:disable-next-line:no-any
    .catch((reason: any) => { onError(reason); }
    );
}

const EnquirySummaryListData = gql`
    query EnquirySummaryListData(
        $offset: Int, 
        $first: Int, 
        $filter: String, 
        $isIncludeCurrent: Boolean, 
        $isIncludeClosed: Boolean,
        $isMyEnquiriesOnly: Boolean,
        $sortColumn: String, 
        $sortDirection: String, 
        $groupBy: String, 
        $groupByValue: String,
        $followUpDue: String,
        $newEnquiry: String,
        $assignedTo: String,
        $areaOfLaw: String,
        $teamList: String,
        $isKeyOpportunity: Boolean,
        $enquiryQuality: String,
        $enquiryLikelihoodToProceed: String,
        $meetingWith: String,
        $status: String
    ) {
        enquiry{
            summaryList(
                offset: $offset, 
                first: $first, 
                filter: $filter, 
                isIncludeCurrent: $isIncludeCurrent, 
                isIncludeClosed: $isIncludeClosed,
                isMyEnquiriesOnly: $isMyEnquiriesOnly,
                sortColumn: $sortColumn, 
                sortDirection: $sortDirection,
                groupBy: $groupBy, 
                groupByValue: $groupByValue,
                followUpDue: $followUpDue,
                newEnquiry: $newEnquiry,
                assignedTo: $assignedTo,
                areaOfLaw: $areaOfLaw,
                teamList: $teamList,
                isKeyOpportunity: $isKeyOpportunity,
                enquiryQuality: $enquiryQuality,
                enquiryLikelihoodToProceed: $enquiryLikelihoodToProceed,
                meetingWith: $meetingWith,
                status: $status
            ) {

                recordCount,
                actualRowCountForSearch,
                summaries{
                    id
                    guid
                    name
                    firstName
                    lastName
                    email  
                }
            }
        }
    }
`;