import React, { useState } from 'react';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
// import { name, random } from 'faker';
import gql from 'graphql-tag';
import { FetchPolicy } from 'apollo-client';
import PersonIcon from '@material-ui/icons/Person';
import PersonOutlinedIcon from '@material-ui/icons/PersonOutlined';
import { TextField, CircularProgress } from '@material-ui/core';
import { RenderInputParams } from '@material-ui/lab';
import { IAutoCompleteItem } from '../../typings/autoComplete';
import { showNotification } from '../../App';
import { InfiniteAutocomplete } from '../../components/InfiniteAutocomplete';
import { client } from '../..';
import GroupOutlinedIcon from '@material-ui/icons/GroupOutlined';
import { useDebouncedCallback } from 'use-debounce';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
        minWidth: '200px',
    },
    textField: {
        width: '100%',
    },
    iconSelected: {
        width: 17,
        height: 17,
        marginRight: 5,
        marginLeft: -2,
      },
      color: {
        width: 14,
        height: 14,
        flexShrink: 0,
        borderRadius: 3,
        marginRight: 8,
        marginTop: 2,
      },
      text: {
        flexGrow: 1,
      },
      close: {
        opacity: 0.6,
        width: 18,
        height: 18,
      },
      renderWrapper: {
          padding: '15px 0'
      },
      customRenderWrapper: {
        display: 'flex',
        margin: '0 -10px',
        width: '100%',
        '& > div': {
            padding: '0 10px',
            fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
            fontWeight: 400,
        },
        '& .icon-wrapper': {
            display: 'flex',
            alignItems: 'center',
        },
        '& .other': {
            flex: 1,
            display: 'flex',
            flexFlow: 'column',
            '& .primary': {
                fontSize: '1rem',
                lineHeight: 1.5,
                letterSpacing: '0.00938em',
            },
            '& .secondary': {
                color: 'rgba(0, 0, 0, 0.54)',
                fontSize: '0.875rem',
                lineHeight: 1.43,
                letterSpacing: '0.01071em',
            },
            '& .third': {
                color: 'rgba(0, 0, 0, 0.54)',
                fontSize: '0.675rem',
                lineHeight: 1.43,
                letterSpacing: '0.01071em',
                fontWeight: 600
            }
        }
      }
  })
);

interface UserStaffAndTeamAutoCompleteSelectorProps {
    multiple?: boolean;
    label?: string;
    name: string;
    required?: boolean;
    // tslint:disable-next-line: no-any
    value?: IAutoCompleteItem | any; // value only available in single select
    onSelection?: (value: IAutoCompleteItem | IAutoCompleteItem[], name: string) => void;
    id?: string;
    error?: boolean;
    disablePortal?: boolean;
    className?: string;
    helperText?: React.ReactNode;
    onBlur?: React.FocusEventHandler<HTMLDivElement>;

    isIncludeCurrent?: boolean; 
    isIncludeClosed?: boolean;
    isMyEnquiriesOnly?: boolean;
    sortColumn?: string; 
    sortDirection?: string; 
    groupBy?: string; 
    groupByValue?: string;
    followUpDue?: string;
    newEnquiry?: string;
    assignedTo?: string;
    areaOfLaw?: string;
    teamList?: string;
    isKeyOpportunity?: boolean;
    enquiryQuality?: string;
    enquiryLikelihoodToProceed?: string;
    isMeetingWithAdvancedFilter?: boolean; 
    sourceList?: string;
}

interface UserStaffAndTeamAutoCompleteSelectorState {
    hasNextPage: boolean;
    isNextPageLoading: boolean;
    offset: number;
    filter: string;
    // tslint:disable-next-line: no-any
    items: any;
    // tslint:disable-next-line: no-any
    teamItems: any;
}

export enum EntityTypeEnum {
    MATTER = 0,
    LEAD = 1,
    CLIENT = 2,
    STAFF = 3,
    USER = 4,
    TASK = 5,
    TEAM = 6,
    GENERAL = 9
}

export const UserStaffAndTeamAutoCompleteSelector: React.FC<UserStaffAndTeamAutoCompleteSelectorProps> = props => {

    const classes = useStyles();
    const loadBlockLimit = 10000;

    const [state, setState] = useState<UserStaffAndTeamAutoCompleteSelectorState>({
        hasNextPage: true,
        isNextPageLoading: false,
        offset: 0,
        filter: '',
        items: [],
        teamItems: [],
    });

    const [debounced] = useDebouncedCallback(
        // function
        (event: React.ChangeEvent<{}>, value: string, reason: 'input' | 'reset' | 'clear') => {
            if (reason === 'input') {
                fetchData(value, 0, true);
            }
        },
        // delay in ms
        500
    );

    const fetchData = (filter: string, offset: number, isClear: boolean) => {

        setState((prevState) => {
            return {
                ...prevState,
                isNextPageLoading: true
            };
        });

        const userQueryParams: MeetingInviteesInFollowUpDataParams = {
            filter: filter,
            first: loadBlockLimit,
            offset: offset,
            isIncludeCurrent: props.isIncludeCurrent,
            isIncludeClosed: props.isIncludeClosed,
            isMyEnquiriesOnly: props.isMyEnquiriesOnly,
            sortColumn: props.sortColumn,
            sortDirection: props.sortDirection,
            groupBy: props.groupBy,
            groupByValue: props.groupByValue,
            followUpDue: props.followUpDue,
            newEnquiry: props.newEnquiry,
            assignedTo: props.assignedTo,
            areaOfLaw: props.areaOfLaw,
            teamList: props.teamList,
            isKeyOpportunity: props.isKeyOpportunity,
            enquiryQuality: props.enquiryQuality,
            enquiryLikelihoodToProceed: props.enquiryLikelihoodToProceed,
            isMeetingWithAdvancedFilter: props.isMeetingWithAdvancedFilter,
            sourceList: props.sourceList
        };

        retrieveMeetingInviteesInFollowUpData(
            userQueryParams,
            false, // this.state.isRefresh, -- Since we are updating and creating, we do not fetch cached data
            // tslint:disable-next-line: no-console
            (data) => onRetrieveUserList(data, filter, offset, isClear),
            // tslint:disable-next-line:no-any
            function (reason: any): void {
                showNotification(null, reason, 'error');

                setState((prevState) => {
                    return {
                        ...prevState,
                        isNextPageLoading: false,
                    };
                });
            }
        );
    };

    // tslint:disable-next-line: max-line-length
    const onRetrieveUserList = (data: MeetingInviteesInFollowUpData, filter: string, offset: number, isClear: boolean) => {

        // tslint:disable-next-line: no-any
        let meetingInviteesData = data.enquiry.meetingInvitees;

        // tslint:disable-next-line: no-any
        let itemsList: any[] = [];

        let items = state.items;

        if (!isClear) {
            itemsList = [...state.items];
        } else {
            items = [];
        }

        if (items && items.length === 0) {
            itemsList = meetingInviteesData.map( (source: MeetingInvitee) => ({
                value: source.inviteeGuid, 
                label: source.inviteeName,
                emailAddress: source.emailAddress,
                isRedViewUser: source.isRedViewUser,
                entityType: source.entityType,
            }));
        } else {
            for (const user of meetingInviteesData) {

                // tslint:disable-next-line: no-any
                const isExists = items.filter((item: any) => item.label.includes(user.inviteeName)).length;

                if (isExists === 0) {
                    itemsList.push({
                        label: user.inviteeName,
                        value: user.inviteeGuid,
                        isRedViewUser: user.isRedViewUser,
                        emailAddress: user.emailAddress,
                        entityType: user.entityType,
                    });
                }
            }
        }

        setState((prevState) => {
            return {
                ...prevState,
                filter: filter,
                isNextPageLoading: false,
                items: itemsList,
                offset: offset + loadBlockLimit,
                hasNextPage: false, // meetingInviteesData.length !== 0 && (offset + loadBlockLimit) < recordCount,
                isLoading: false
            };
        });

    };

    // tslint:disable-next-line: no-any
    const loadNextPage = (...args: any) => {
        
        if (state.hasNextPage && !state.isNextPageLoading) {
            fetchData(state.filter, state.offset, false);
        }
    };

    const onClose = (event: React.ChangeEvent<{}>) => {
        // tslint:disable-next-line: no-console
        // console.log('onClose Event', event);

        setState((prevState) => {
            return {
                ...prevState,
                isNextPageLoading: false,
                hasNextPage: true,
                filter: '',
                offset: 0,
                items: []
            };
        });
    };

    const onOpen = (event: React.ChangeEvent<{}>) => {
        // tslint:disable-next-line: no-console
        fetchData(state.filter, 0, true);
    };

    const onSelection = (selection: IAutoCompleteItem, name: string) => {
        // tslint:disable-next-line: no-console
        // console.log('onSelection Event', selection);

        if (props.onSelection) {
            props.onSelection(selection, name);
        }
    };

    const getInputProps = (params: RenderInputParams, isLoading?: boolean) => {
        return  ({   
            ...params.InputProps,
            endAdornment: (
                <React.Fragment>
                  {isLoading ? <CircularProgress color="primary" size={20} /> : null}
                  {params.InputProps.endAdornment}
                </React.Fragment>
              ),     
         });
    };

    return (
        <div className={`${classes.root} ${props.className}`}>
            <InfiniteAutocomplete
                id={props.id}
                itemSize={70}
                loading={state.isNextPageLoading}
                disablePortal={props.disablePortal !== undefined ? props.disablePortal : true}
                name={props.name}
                isMultiple={props.multiple}
                value={props.value}
                hasNextPage={state.hasNextPage}
                isNextPageLoading={state.isNextPageLoading}
                items={state.items}
                loadNextPage={loadNextPage}
                // disableCloseOnSelect={true}
                className={classes.textField}
                disableListWrap={true}
                onSelection={onSelection}
                onClose={onClose}
                onOpen={onOpen}
                getOptionLabel={(option: IAutoCompleteItem) => option.label}
                getOptionSelected={(option, value) => value.value === option.value}
                onInputChange={(event: React.ChangeEvent<{}>, value: string, reason: 'input' | 'reset' | 'clear') => debounced(event, value, reason)}
                onBlur={props.onBlur}
                renderOption={(option, { selected }) => {
                    return (
                        <div className={classes.customRenderWrapper}>
                            <div className="icon-wrapper">
                                {option.entityType === EntityTypeEnum.TEAM ? 
                                    <GroupOutlinedIcon fontSize="large"/> : option.isRedViewUser ? 
                                    <PersonIcon fontSize="large"/> : <PersonOutlinedIcon fontSize="large"/>}
                            </div>
                            <div className="other">
                                <div className="primary">{option.label}</div>
                                {option.entityType === EntityTypeEnum.STAFF && (
                                    <>
                                        {!!option.emailAddress && (
                                            <div className="secondary">{option.emailAddress}</div>
                                        )}
                                        {/* {!!props.visible && props.visible!.role && (
                                            <div className="third">{option.roleName}</div>
                                        )} */}
                                    </>
                                )}
                            </div>
                        </div>
                    );
                }}
                renderInput={params => (
                    <TextField
                        {...params}
                        variant="standard"
                        label={props.label}
                        required={props.required}
                        error={props.error}
                        fullWidth={true}
                        InputProps={getInputProps(params, state.isNextPageLoading)}
                        helperText={props.helperText}
                    />
                )}
            />
        </div>
    );
};

export function retrieveMeetingInviteesInFollowUpData(
    query: MeetingInviteesInFollowUpDataParams,
    refreshData: boolean,
    onSuccess: (data: MeetingInviteesInFollowUpData) => void,
    // tslint:disable-next-line:no-any
    onError: (reason: any) => void
): void {
    var fetchPolicy: FetchPolicy = refreshData === true ? 'network-only' : 'cache-first';
    client
        .query({
            query: MEETING_INVITEES_FOLLOW_UP_QUERY,
            variables: {
                offset: query.offset,
                first: query.first,
                filter: query.filter,
                isIncludeCurrent: query.isIncludeCurrent,
                isIncludeClosed: query.isIncludeClosed,
                isMyEnquiriesOnly: query.isMyEnquiriesOnly,
                sortColumn: query.sortColumn,
                sortDirection: query.sortDirection,
                groupBy: query.groupBy,
                groupByValue: query.groupByValue,
                followUpDue: query.followUpDue,
                newEnquiry: query.newEnquiry,
                assignedTo: query.assignedTo,
                areaOfLaw: query.areaOfLaw,
                teamList: query.teamList,
                isKeyOpportunity: query.isKeyOpportunity,
                enquiryQuality: query.enquiryQuality,
                enquiryLikelihoodToProceed: query.enquiryLikelihoodToProceed,
                isMeetingWithAdvancedFilter: query.isMeetingWithAdvancedFilter,
                sourceList: query.sourceList
            },
            fetchPolicy: fetchPolicy
        })
        // tslint:disable-next-line:no-any
        .then((results: { data: any }) => {
            onSuccess(results.data);
        })
        // tslint:disable-next-line:no-any
        .catch((reason: any) => {
            onError(reason);
        });
}

export interface MeetingInviteesInFollowUpDataParams {
    offset?: number; 
    first?: number; 
    filter?: string;  
    isIncludeCurrent?: boolean; 
    isIncludeClosed?: boolean;
    isMyEnquiriesOnly?: boolean;
    sortColumn?: string; 
    sortDirection?: string; 
    groupBy?: string; 
    groupByValue?: string;
    followUpDue?: string;
    newEnquiry?: string;
    assignedTo?: string;
    areaOfLaw?: string;
    teamList?: string;
    isKeyOpportunity?: boolean;
    enquiryQuality?: string;
    enquiryLikelihoodToProceed?: string;
    isMeetingWithAdvancedFilter?: boolean; 
    sourceList?: string;
}

export interface MeetingInviteesInFollowUpData {
    enquiry: Enquiry;
}

export interface Enquiry {
    meetingInvitees: MeetingInvitee[];
}

export interface MeetingInvitee {
    id:          number;
    entityType:  number;
    inviteeGuid: string;
    inviteeName: string;
    isActive:    boolean;
    isRedViewUser: boolean;
    emailAddress: string;
}

const MEETING_INVITEES_FOLLOW_UP_QUERY = gql`
    query meetingInviteesAdvancedFilter (
        $offset: Int, 
        $first: Int, 
        $filter: String,  
        $isIncludeCurrent: Boolean, 
        $isIncludeClosed: Boolean,
        $isMyEnquiriesOnly: Boolean,
        $sortColumn: String, 
        $sortDirection: String, 
        $groupBy: String, 
        $groupByValue: String,
        $followUpDue: String,
        $newEnquiry: String,
        $assignedTo: String,
        $areaOfLaw: String,
        $teamList: String,
        $isKeyOpportunity: Boolean,
        $enquiryQuality: String,
        $enquiryLikelihoodToProceed: String,
        $isMeetingWithAdvancedFilter: Boolean, 
        $sourceList: String
    ){
        enquiry{
            meetingInvitees (
                offset: $offset, first: $first, filter: $filter, 
                isIncludeCurrent: $isIncludeCurrent, 
                isIncludeClosed: $isIncludeClosed,
                isMyEnquiriesOnly: $isMyEnquiriesOnly,
                sortColumn: $sortColumn, 
                sortDirection: $sortDirection,
                groupBy: $groupBy, 
                groupByValue: $groupByValue,
                followUpDue: $followUpDue,
                newEnquiry: $newEnquiry,
                assignedTo: $assignedTo,
                areaOfLaw: $areaOfLaw,
                teamList: $teamList,
                isKeyOpportunity: $isKeyOpportunity,
                enquiryQuality: $enquiryQuality,
                enquiryLikelihoodToProceed: $enquiryLikelihoodToProceed,
                isMeetingWithAdvancedFilter: $isMeetingWithAdvancedFilter,
              	sourceList: $sourceList
            ) {
                id,
                entityType,
                inviteeGuid,
                inviteeName,
                isActive,
                isRedViewUser,
                emailAddress
            }
        }
    }
`;