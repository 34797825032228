/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import { Autocomplete, RenderInputParams } from '@material-ui/lab';
import { TextField, CircularProgress } from '@material-ui/core';
import match from 'autosuggest-highlight/match';
import parse from 'autosuggest-highlight/parse';
import { showNotification } from '../../App';
import { IAutoCompleteItem } from '../../typings/autoComplete';
import gql from 'graphql-tag';
import { FetchPolicy } from 'apollo-client';
import { client } from '../..';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
        width: '100%'
    },
  })
);

interface AreaOfLawSelectorProps {
    disabled?: boolean;
    required?: boolean;
    multiple?: boolean;
    // tslint:disable-next-line: no-any
    value?: IAutoCompleteItem | any; // value only available in single select
    error?: boolean;
    onSelection?: (value: IAutoCompleteItem | IAutoCompleteItem[] | IAreaOfLawAutoCompleteItem | IAreaOfLawAutoCompleteItem[], name: string) => void;
    className?: string;
    helperText?: string;
    name?: string;
    label?: string;
    disablePortal?: boolean;
    disableCloseOnSelect?: boolean;
    onBlur?: React.FocusEventHandler<HTMLDivElement>;
}

interface AreaOfLawSelectorState {
    isLoading: boolean;
    areaOfLaw: IAutoCompleteItem[];
}

// tslint:disable-next-line: interface-name
export interface IAreaOfLawAutoCompleteItem extends IAutoCompleteItem {
    estimatedFees: number;
    isEstimatedFeesIncludesTax: boolean;
    estimatedDisbursements: number;
    isEstimatedDisbursementsIncludesTax: boolean;
}

export const AreaOfLawSelector: React.FC<AreaOfLawSelectorProps> = props => {

    const classes = useStyles();

    const [state, setState] = useState<AreaOfLawSelectorState>({
        isLoading: false,
        areaOfLaw: [],
    });

    useEffect(() => {   
        fetchData();
    // tslint:disable-next-line: align
    }, []);

    const fetchData = () => {

        setState({
            ...state,
            isLoading: true
        });

        retrieveAreaOfLawData(
            false,
            // tslint:disable-next-line: no-console
            (data) => onRetrieveAreaOfLaw(data),
            // tslint:disable-next-line:no-any
            function (reason: any): void {
                showNotification(null, reason, 'error');
            }
        );
    };

    const onRetrieveAreaOfLaw = (data: AreaOfLawData) => {
        setState({
            ...state,
            areaOfLaw: data.lookup.areaOfLaw.map((source: AreaOfLaw) => ({
                value: source.guidId,
                label: source.description,
                estimatedFees: source.estimatedFees,
                isEstimatedFeesIncludesTax: source.isEstimatedFeesIncludesTax,
                estimatedDisbursements: source.estimatedDisbursements,
                isEstimatedDisbursementsIncludesTax: source.isEstimatedDisbursementsIncludesTax
            })),
            isLoading: false
        });
    };

    // tslint:disable-next-line: no-any
    const handleChange = (event: React.ChangeEvent<{}>, value: any) => {
        // tslint:disable-next-line: no-console
        console.log('onChange', value);
        if (props.onSelection) {
            props.onSelection(value, props.name ? props.name : 'areaOfLaw');
        }
    };

    const getInputProps = (params: RenderInputParams, isLoading?: boolean) => {
        return  ({   
            ...params.InputProps,
            endAdornment: (
                <React.Fragment>
                  {isLoading ? <CircularProgress color="primary" size={20} /> : null}
                  {params.InputProps.endAdornment}
                </React.Fragment>
              ),     
         });
    };

    return (
        <div className={`${classes.root} ${props.className}`}>
            { props.multiple ? 
                (
                    <Autocomplete
                        value={props.value}
                        multiple={true}
                        loading={state.isLoading}
                        disabled={props.disabled}
                        onChange={handleChange}
                        options={state.areaOfLaw}
                        disableCloseOnSelect={props.disableCloseOnSelect}
                        disablePortal={props.disablePortal} // to Stop the dropdown select from closing the form
                        // tslint:disable-next-line: jsx-no-lambda
                        getOptionLabel={(option: IAutoCompleteItem) => option.label}       
                        getOptionSelected={(option, value) => value.value === option.value}     
                        onBlur={props.onBlur}        
                        // tslint:disable-next-line: jsx-no-lambda
                        renderInput={params => (
                            <TextField 
                                {...params} 
                                label={props.label ? props.label : 'Area of Law'}
                                variant="standard" 
                                fullWidth={true} 
                                margin="none" 
                                required={props.required}
                                InputProps={getInputProps(params, state.isLoading)}
                                error={props.error}      
                                helperText={props.helperText}                      
                            />
                        )}
                        // tslint:disable-next-line: jsx-no-lambda
                        renderOption={(option, { inputValue }) => {
                            const matches = match(option.label, inputValue);
                            const parts = parse(option.label, matches);
                    
                            return (
                                <div>
                                    {parts.map((part, index) => (
                                        <span key={index} style={{ fontWeight: part.highlight ? 700 : 400 }}>
                                            {part.text}
                                        </span>
                                    ))}
                                </div>
                            );
                        }}
                    />
                )
                :
                (
                    <Autocomplete
                        value={props.value}
                        loading={state.isLoading}
                        disabled={props.disabled}
                        onChange={handleChange}
                        options={state.areaOfLaw}
                        disableCloseOnSelect={props.disableCloseOnSelect}
                        disablePortal={props.disablePortal} // to Stop the dropdown select from closing the form
                        // tslint:disable-next-line: jsx-no-lambda
                        getOptionLabel={(option: IAutoCompleteItem) => option.label}    
                        getOptionSelected={(option, value) => value.value === option.value}  
                        onBlur={props.onBlur}   
                        // tslint:disable-next-line: jsx-no-lambda
                        renderInput={params => (
                            <TextField 
                                {...params} 
                                label={props.label ? props.label : 'Area of Law'}
                                variant="standard" 
                                fullWidth={true} 
                                margin="none" 
                                required={props.required}
                                InputProps={getInputProps(params, state.isLoading)}
                                error={props.error}    
                                helperText={props.helperText}                        
                            />
                        )}
                        // tslint:disable-next-line: jsx-no-lambda
                        renderOption={(option, { inputValue }) => {
                            const matches = match(option.label, inputValue);
                            const parts = parse(option.label, matches);
                    
                            return (
                                <div>
                                {parts.map((part, index) => (
                                    <span key={index} style={{ fontWeight: part.highlight ? 700 : 400 }}>
                                    {part.text}
                                    </span>
                                ))}
                                </div>
                            );
                        }}
                    />
                )
            }
        </div>
    );
};

export function retrieveAreaOfLawData(
    refreshData: boolean,
    onSuccess: (data: AreaOfLawData) => void,
    // tslint:disable-next-line:no-any
    onError: (reason: any) => void
): void {
    var fetchPolicy: FetchPolicy = refreshData === true ? 'network-only' : 'cache-first';
    client
        .query({
            query: LeadAreaOfLawQuery,
            variables: {},
            fetchPolicy: fetchPolicy,
        })
        // tslint:disable-next-line:no-any
        .then((results: { data: any }) => {
            onSuccess(results.data);
        })
        // tslint:disable-next-line:no-any
        .catch((reason: any) => {
            onError(reason);
        });
}

export interface AreaOfLawData {
    loading?: boolean;
    networkStatus?: number;
    lookup: Lookup;
}

export interface Lookup {
    areaOfLaw: AreaOfLaw[];
}

export interface AreaOfLaw {
    guidId:      string;
    description: string;
    isAvailableInAPI: boolean;
    estimatedFees: number;
    isEstimatedFeesIncludesTax: boolean;
    estimatedDisbursements: number;
    isEstimatedDisbursementsIncludesTax: boolean;
}

const LeadAreaOfLawQuery = gql`
    query LookupAreaOfLaw{
        lookup {
            areaOfLaw{
                guidId      
                description,
                sequence,
                isAvailableInAPI
                estimatedFees
                isEstimatedFeesIncludesTax
          	    estimatedDisbursements
          	    isEstimatedDisbursementsIncludesTax
            }
        }
    }
`;
