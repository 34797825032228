/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import { Autocomplete, RenderInputParams } from '@material-ui/lab';
import { TextField, CircularProgress } from '@material-ui/core';
import match from 'autosuggest-highlight/match';
import parse from 'autosuggest-highlight/parse';
import { showNotification } from '../../App';
import { IAutoCompleteItem } from '../../typings/autoComplete';
import gql from 'graphql-tag';
import { client } from '../..';
import { FetchPolicy } from 'apollo-client';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
        minWidth: '200px',
    },
  })
);
export interface RecurringMatterItem extends IAutoCompleteItem, RecurringMatterTemplate {}

interface RecurringMatterSelectorProps {
    disabled?: boolean;
    required?: boolean;
    multiple?: boolean;
    // tslint:disable-next-line: no-any
    value?: IAutoCompleteItem | any; // value only available in single select
    error?: boolean;
    onSelection?: (value: RecurringMatterItem | RecurringMatterItem[], name: string) => void;
    className?: string;
    helperText?: string;
    name?: string;
    label?: string;
    disablePortal?: boolean;
    onBlur?: React.FocusEventHandler<HTMLDivElement>;
}

interface RecurringMatterSelectorState {
    isLoading: boolean;
    options: IAutoCompleteItem[];
}

export const RecurringMatterSelector: React.FC<RecurringMatterSelectorProps> = props => {

    const classes = useStyles();

    const [state, setState] = useState<RecurringMatterSelectorState>({
        isLoading: false,
        options: [],
    });

    useEffect(() => {   
        fetchData(false);
    // tslint:disable-next-line: align
    }, []);

    const fetchData = (refresh: boolean) => {

        setState({
            ...state,
            isLoading: true
        });

        retrieveRecurringMatterData(
            refresh, // this.state.isRefresh, -- Since we are updating and creating, we do not fetch cached data
            // tslint:disable-next-line: no-console
            (data) => onRetrieveRecurringMatter(data),
            // tslint:disable-next-line:no-any
            function (reason: any): void {
                showNotification(null, reason, 'error');
            }
        );
    };

    const onRetrieveRecurringMatter = (data: RecurringMatterData) => {
        setState((prevState) => {
            return {
                ...prevState,
                // tslint:disable-next-line: max-line-length
                options: data.recurringMatterTemplates.recurringMatterTemplates.map( (source: RecurringMatterTemplate) => ({...source, value: source.recurringMatterId, label: source.name })),
                isLoading: false
            };
        });
    };

    // tslint:disable-next-line: no-any
    const handleChange = (event: React.ChangeEvent<{}>, value: any) => {
        // tslint:disable-next-line: no-console
        console.log('onChange', value);
        if (props.onSelection) {
            props.onSelection(value, props.name ? props.name : 'status');
        }
    };

    const onOpen = () => {
        fetchData(false);
    };

    const getInputProps = (params: RenderInputParams, isLoading?: boolean) => {
        return  ({   
            ...params.InputProps,
            endAdornment: (
                <React.Fragment>
                  {isLoading ? <CircularProgress color="primary" size={20} /> : null}
                  {params.InputProps.endAdornment}
                </React.Fragment>
              ),     
         });
    };

    return (
        <div className={`${classes.root} ${props.className}`}>
            { props.multiple ? 
                (
                    <Autocomplete
                        value={props.value}
                        multiple={true}
                        loading={state.isLoading}
                        disabled={props.disabled}
                        onChange={handleChange}
                        onOpen={onOpen}
                        options={state.options}
                        disablePortal={props.disablePortal} // to Stop the dropdown select from closing the form
                        // tslint:disable-next-line: jsx-no-lambda
                        getOptionLabel={(option: IAutoCompleteItem) => option.label}   
                        onBlur={props.onBlur}                 
                        // tslint:disable-next-line: jsx-no-lambda
                        renderInput={params => (
                            <TextField 
                                {...params} 
                                label={props.label ? props.label : 'Status'}
                                variant="standard" 
                                fullWidth={true} 
                                margin="none" 
                                required={props.required}
                                InputProps={getInputProps(params, state.isLoading)}
                                error={props.error}      
                                helperText={props.helperText}                      
                            />
                        )}
                        // tslint:disable-next-line: jsx-no-lambda
                        renderOption={(option, { inputValue }) => {
                            const matches = match(option.label, inputValue);
                            const parts = parse(option.label, matches);
                    
                            return (
                                <div>
                                    {parts.map((part, index) => (
                                        <span key={index} style={{ fontWeight: part.highlight ? 700 : 400 }}>
                                            {part.text}
                                        </span>
                                    ))}
                                </div>
                            );
                        }}
                    />
                )
                :
                (
                    <Autocomplete
                        value={props.value}
                        loading={state.isLoading}
                        disabled={props.disabled}
                        onChange={handleChange}
                        onOpen={onOpen}
                        options={state.options}
                        disablePortal={props.disablePortal} // to Stop the dropdown select from closing the form
                        // tslint:disable-next-line: jsx-no-lambda
                        getOptionLabel={(option: IAutoCompleteItem) => option.label}   
                        onBlur={props.onBlur}      
                        // tslint:disable-next-line: jsx-no-lambda
                        renderInput={params => (
                            <TextField 
                                {...params} 
                                label={props.label ? props.label : 'Status'}
                                variant="standard" 
                                fullWidth={true} 
                                margin="none" 
                                required={props.required}
                                InputProps={getInputProps(params, state.isLoading)}
                                error={props.error}      
                                helperText={props.helperText}                              
                            />
                        )}
                        // tslint:disable-next-line: jsx-no-lambda
                        renderOption={(option, { inputValue }) => {
                            const matches = match(option.label, inputValue);
                            const parts = parse(option.label, matches);
                    
                            return (
                                <div>
                                {parts.map((part, index) => (
                                    <span key={index} style={{ fontWeight: part.highlight ? 700 : 400 }}>
                                    {part.text}
                                    </span>
                                ))}
                                </div>
                            );
                        }}
                    />
                )
            }
        </div>
    );
};

export function retrieveRecurringMatterData(
    refreshData: boolean,
    onSuccess: (data: RecurringMatterData) => void,
    // tslint:disable-next-line:no-any
    onError: (reason: any) => void
): void  {
    var fetchPolicy: FetchPolicy = refreshData === true ? 'network-only' : 'cache-first';
    client
        .query({
            query: RecurringMatterTemplatesQuery,
            variables: {},
            fetchPolicy: fetchPolicy,
        })
        // tslint:disable-next-line:no-any
        .then((results: { data: any }) => {
            onSuccess(results.data);
        })
        // tslint:disable-next-line:no-any
        .catch((reason: any) => {
            onError(reason);
        });
}

export interface RecurringMatterData {
    loading?: boolean;
    networkStatus?: number;
    recurringMatterTemplates: RecurringMatterTemplates;
}

export interface RecurringMatterTemplates {
    recordCount:              number;
    recurringMatterTemplates: RecurringMatterTemplate[];
}

export interface RecurringMatterTemplate {
    name: string;
    matterTypeId: string;
    recurringMatterId: string;
    matterTypeName: string;
    state: string;
    referrerGuid: string;
    referrerId: Number;
    referrerName: string;
    responsibleGuid: string;
    responsibleId: Number;
    responsibleName: string;
    actingGuid: string;
    actingId: Number;
    actingName: string;
    assistingGuid: string;
    assistingId: Number;
    assistingName: string;
    creditGuid: string;
    creditId: Number;
    creditName: string;
    costsEstimate: Number;
    isEstimatedFeesIncludesTax: Boolean;
    disbursementsEstimate: Number;
    isEstimatedDisbursementsIncludesTax: Boolean;
    costAgreementRequired: boolean;
    workObtained: string | null;
    reportingGroupGuid: string | null;
    reportingGroup: string | null;
    matterTitle: string | null;
}

export const RecurringMatterTemplatesQuery = gql`
    query recurringMatterTemplates {
        recurringMatterTemplates {
            recordCount
            recurringMatterTemplates {
                name
                matterTypeId
                matterTypeName
                recurringMatterId
                state
                referrerGuid
                referrerId
                referrerName
                responsibleGuid
                responsibleId
                responsibleName
                actingGuid
                actingId
                actingName
                assistingGuid
                assistingId
                assistingName
                creditGuid
                creditId
                creditName
                costsEstimate
                isEstimatedFeesIncludesTax
                disbursementsEstimate
                isEstimatedDisbursementsIncludesTax
                costAgreementRequired
                workObtained
              	reportingGroupGuid
              	reportingGroup
                matterTitle
            }
        }
    }
`;